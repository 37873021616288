import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Tabs, Tab } from "@mui/material";

import { TabPanel } from "../TabPanel";
import PropTypes from "prop-types";

export const TabLayout = ({ children }) => {
  const [value, setValue] = useState(0);

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{ borderBottom: 1, borderColor: "divider" }}
        style={{ paddingLeft: "10px" }}
      >
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs">
          {children.map((child, index) => {
            return (
              <Tab
                key={index.toString()}
                label={child.props.pageTitle}
                {...a11yProps(index)}
              />
            );
          })}
        </Tabs>
      </Box>
      {children.map((child, index) => {
        return (
          <TabPanel key={index.toString()} value={value} index={index}>
            {child}
          </TabPanel>
        );
      })}
    </Box>
  );
};

TabLayout.propTypes = {
  children: PropTypes.node,
};
