export const STATUS = {
  PROJECT: {
    IN_PREPARATION: "In Preparation for Submission",
    CLOSED: "Closed",
    RESPONDING: "Responding to Agency",
    AGENCY: "Agency Review",
    ARCHIVED: "Archived",
    ON_HOLD: "On Hold",
  },
  DOCUMENT: {
    IN_PREPARATION: "In Preparation",
    READY: "Ready",
  },
  FINDING: {
    IN_PREPARATION: "In Preparation",
    CLOSED: "Closed",
    READY: "Ready",
    SUBMITTED: "Submitted",
  },
};
