import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import "./style.css";

export function ModalFooter({ buttons = [], children }) {
  const DEFAULT_BUTTON_WIDTH = 115;

  return (
    <div className="button_wrapper">
      {buttons.length
        ? buttons
            ?.filter(({ visible = true }) => !!visible)
            .map(
              (
                {
                  variant = "contained",
                  color = "primary",
                  onClick = () => {},
                  disabled = false,
                  label = "Label",
                  btnStyle,
                  isLoading = false,
                  width = DEFAULT_BUTTON_WIDTH,
                  id,
                },
                index
              ) => {
                return (
                  <Button
                    key={id || index}
                    isLoading={isLoading}
                    variant={variant}
                    color={color}
                    className={classnames({
                      button_container: true,
                      white_btn: btnStyle === "white",
                      black_btn: btnStyle === "black",
                    })}
                    onClick={onClick}
                    disabled={disabled}
                    width={width}
                  >
                    {label}
                  </Button>
                );
              }
            )
        : children}
    </div>
  );
}

ModalFooter.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      variant: PropTypes.string,
      visible: PropTypes.bool,
      color: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
      label: PropTypes.string,
      isLoading: PropTypes.bool,
      width: PropTypes.number,
    })
  ).isRequired,
  justify: PropTypes.string,
};

ModalFooter.defaultProps = {
  justify: "flex-end",
};
