import { SET_SESSION_DATA } from "../../actionTypes/session";

export function setSessionData(data) {
  return async (dispatch) => {
    dispatch({
      type: SET_SESSION_DATA,
      payload: data,
    });
  };
}
