import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  ModalContainer,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "../../ui-components/ModalConstructor";
import { TextField, Box } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { formatDateToISOString } from "../../../utils/formatDate";
import { createReminder } from "../../../store/actions/reminder";

import "./style.css";

export const ReminderModal = ({
  onClose,
  title,
  description,
  data,
  topic,
  width = "500px",
  isLoading = false,
  cancelButtonProps = {},
  submitButtonProps = {},
  ref,
}) => {
  const dispatch = useDispatch();
  const [dateValue, setDateValue] = useState(null);
  const [notesValue, setNotesValue] = useState("");
  const [companyId, setCompanyId] = useState("");
  const dateNow = Date.now();

  useEffect(() => {
    setCompanyId(data?._id);
  });

  const handleChangeDate = (newDate) => {
    const formatedDate = formatDateToISOString(newDate);

    setDateValue(formatedDate);
  };

  const handleChangeNotes = (e) => {
    setNotesValue(e.target.value);
  };

  const handleSubmit = async () => {
    try {
      const data = {
        name: notesValue,
        date: dateValue,
        companyProject: {
          _id: companyId,
        },
      };
      dispatch(createReminder(data));
      onClose();
    } catch (error) {
      //console.log(error);
    }
  };

  return (
    <ModalContainer width={width} onClose={onClose} ref={ref}>
      <ModalHeader
        onClose={onClose}
        title={title}
        description={description}
        type="REMINDER"
        topic={topic}
      />
      <ModalContent>
        <Box>
          <p className="reminder_label"> Select Date</p>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={dateValue}
              onChange={handleChangeDate}
              minDate={dateNow}
              //   label="Select"
              renderInput={(params) => {
                return (
                  <TextField
                    className="reminder_date_picker note"
                    {...params}
                  />
                );
              }}
            />
          </LocalizationProvider>
          <div style={{ marginTop: "30px" }}>
            <p className="reminder_label"> Add a Note</p>
            <div
              style={{
                width: "100%",
                // minHeight: "130px",
                height: "88px",
                border: "1px solid #BDBDBD",
                padding: "8px 10px",
                borderRadius: "4px",
              }}
            >
              <TextField
                value={notesValue}
                onChange={handleChangeNotes}
                name="name"
                sx={{ border: "none", width: "100%" }}
                multiline
                variant="standard"
                placeholder="Enter"
                className="notes"
              />
            </div>
          </div>
        </Box>
      </ModalContent>
      <ModalFooter
        buttons={[
          {
            label: "Cancel",
            isLoading: false,
            visible: true,
            onClick: onClose,
            btnStyle: "white",
            ...cancelButtonProps,
          },
          {
            label: "Set Reminder",
            isLoading,
            visible: true,
            onClick: handleSubmit,
            btnStyle: "black",
            ...submitButtonProps,
          },
        ]}
      />
    </ModalContainer>
  );
};

ReminderModal.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  isLoading: PropTypes.bool,
};
ReminderModal.defaultProps = {
  onClose: () => {},
  onSubmit: () => {},
  title: "",
  description: "",
  isLoading: false,
};
