import * as React from "react";

const EditDocument = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.333 1 17 3.667 8.467 12.2l-3.734 1.067L5.8 9.533 14.333 1ZM12.2 3.133 14.867 5.8"
      stroke="#4F4F4F"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.867 10.138v5.718c0 .632-.513 1.144-1.144 1.144H2.143A1.144 1.144 0 0 1 1 15.856V3.276c0-.63.512-1.143 1.144-1.143h5.718"
      stroke="#4F4F4F"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EditDocument;
