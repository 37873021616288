import Base from "./Base";

export default class NotesApi extends Base {
  getNotes(params) {
    return this.apiClient.get("note", params);
  }
  createNote(body) {
    return this.apiClient.post("note/submission", body);
  }
  deleteNote(id) {
    return this.apiClient.delete(`note/submission/${id}`);
  }
}
