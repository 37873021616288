import React from "react";

const UserMenagementIcon = (props) => (
  <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.5 10c-2.3 0-4.167-2.7-4.167-5v-.833a4.167 4.167 0 0 1 8.334 0V5c0 2.3-1.866 5-4.167 5ZM10 15c0-1.966 1.667-2.5 1.667-2.5s-1.68-.834-4.167-.834c-2.353 0-4.44.532-5.812.995A2.492 2.492 0 0 0 0 15.027v3.306h10V15ZM19.167 14.167h-.834V12.5c0-1.378-1.121-2.5-2.5-2.5a2.503 2.503 0 0 0-2.5 2.5v1.667H12.5a.834.834 0 0 0-.833.833v4.167c0 .46.373.833.833.833h6.667c.46 0 .833-.373.833-.833V15a.834.834 0 0 0-.833-.833ZM15 12.5c0-.46.374-.833.833-.833.46 0 .834.374.834.833v1.667H15V12.5Z"
      fill={props.color}
    />
  </svg>
);

export default UserMenagementIcon;
