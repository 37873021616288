import { Formio } from "@formio/react";

export const AuthKeys = {
  SESSION: "session",
  IS_AUTHENTICATED: "isAuthenticated",
};

export const Auth = () => {
  const setUser = (user) => {
    localStorage.setItem(AuthKeys.SESSION, JSON.stringify(user));
    localStorage.setItem(AuthKeys.IS_AUTHENTICATED, "true");
    Formio.setToken(user.accessToken);
  };

  const getUser = () => {
    let json = localStorage.getItem(AuthKeys.SESSION);
    if (json == null) return null;
    return JSON.parse(json);
  };

  const isAuthenticated = () => {
    let json = localStorage.getItem(AuthKeys.IS_AUTHENTICATED);
    if (json == null) return false;
    return localStorage.getItem(AuthKeys.IS_AUTHENTICATED);
  };

  const logOut = () => {
    if (isAuthenticated()) {
      localStorage.setItem(AuthKeys.SESSION, null);
      localStorage.setItem(AuthKeys.IS_AUTHENTICATED, "false");
      localStorage.removeItem(AuthKeys.SESSION);
      return true;
    }
    return false;
  };

  const getAuthHeaders = () => {
    const user = getUser();
    return user != null
      ? {
          "Content-Type": "application/json",
          accept: "application/json",
          Authorization: "bearer " + user.accessToken,
          "x-jwt-token": user.accessToken,
        }
      : {};
  };

  const getAuthHeadersKV = () => {
    const user = getUser();
    return user != null
      ? [
          {
            key: "Content-Type",
            value: "application/json",
          },
          {
            key: "accept",
            value: "application/json",
          },
          {
            key: "Authorization",
            value: "bearer " + user.accessToken,
          },
          {
            key: "x-jwt-token",
            value: user.accessToken,
          },
        ]
      : [];
  };

  return {
    setUser,
    getUser,
    isAuthenticated,
    logOut,
    getAuthHeaders,
    getAuthHeadersKV,
  };
};
