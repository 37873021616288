import * as React from "react";

const ImportIcon = (props) => (
  <svg
    width={48}
    height={45}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={0.909}
      width={47.045}
      height={45}
      rx={12}
      fill="#2F80ED"
      fillOpacity={0.1}
    />
    <path
      d="M15.432 19.552h18M15.432 14.5h18M15.432 24.605h8.182M15.432 29.658h8.182M30.16 23.763v6.736M33.432 27.131h-6.546"
      stroke="#2F80ED"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);

export default ImportIcon;
