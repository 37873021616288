import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { AdminTable } from "../../components/Table/AdminTable";
import Divider from "@mui/material/Divider";
import { FilterButton } from "../../components/PageFilters";
import { adminSelector } from "../../store/selectors/admin";
import { getAdminUsers } from "../../store/actions/admin";
import { Auth } from "../../modules/auth";
import { PageSearch } from "../../components/PageSearch";
import { ROUTES } from "../../router";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "../../hooks/useDebounce";

export const AdminManagementPage = () => {
  const buttons = [
    {
      id: 1,
      value: "All users",
    },
    {
      id: 2,
      value: "Active Users",
    },
    {
      id: 3,
      value: "Inactive Users",
    },
    {
      id: 4,
      value: "Administrators",
    },
    {
      id: 5,
      value: "Consultants",
    },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = Auth();
  const [filteredValue, setFilteredValue] = useState("All users");
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const adminData = useSelector(adminSelector);
  const debouncedValue = useDebounce(searchValue);

  useEffect(() => {
    fetchAdminUsers();
  }, [filteredValue]);

  useEffect(() => {
    if (auth.getUser() === null || auth.getUser().superAdmin !== true) {
      navigate(`${ROUTES.DASHBOARD}`);
    }
  }, []);

  useEffect(() => {
    const filteredData = filterBySearchQuery(adminData);
    setData(filteredData);
  }, [debouncedValue, adminData]);

  const fetchAdminUsers = async () => {
    const queryParams = getQueryParam(filteredValue);
    dispatch(getAdminUsers(queryParams));
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  const filterBySearchQuery = (data = []) => {
    return data?.filter((item) => {
      const FILTERED_FIELDS = [item.data.name, item.data.email];

      if (!debouncedValue) {
        return true;
      }
      return FILTERED_FIELDS.some((field) =>
        field.toLowerCase().includes(debouncedValue.toLowerCase())
      );
    });
  };

  const getQueryParam = (value) => {
    switch (value) {
      case "All users":
        return {};
      case "Active Users":
        return { status: "active" };
      case "Inactive Users":
        return { status: "inactive" };
      case "Administrators":
        return { superAdmin: true };
      case "Consultants":
        return { consultant: true };
      default:
        return {};
    }
  };

  return (
    <div>
      <Grid
        container
        justifyContent="space-between"
        style={{
          alignItems: "center",
          paddingTop: "16px",
          paddingLeft: "24px",
          paddingRight: "24px",
        }}
      >
        <Grid item xs={9}>
          <Grid container flexDirection="column">
            <Grid item>
              <Breadcrumbs>
                <Typography style={{ fontSize: "12px" }}>Dashboard</Typography>
                <Typography style={{ fontSize: "12px", color: "black" }}>
                  Admin Management
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item style={{ marginBottom: "16px" }}>
              <Typography style={{ fontSize: "24px", fontWeight: "600" }}>
                Admin Management
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Grid
        container
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0px 24px",
        }}
      >
        <Grid item>
          <PageSearch value={searchValue} handleSearch={handleSearch} />
        </Grid>
        <Grid item>
          <FilterButton
            buttons={buttons}
            filter={filteredValue}
            changeFilter={setFilteredValue}
          />
        </Grid>
        <Grid
          item
          style={{
            marginTop: "-31px",
          }}
        >
          <AdminTable
            pageTitle={"Admins"}
            addButtonTitle={"Add an Admin"}
            tableData={data}
            fetchData={fetchAdminUsers}
          />
        </Grid>
      </Grid>
    </div>
  );
};
