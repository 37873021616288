import {
  FETCH_COMPANIES_START,
  FETCH_COMPANIES_END,
  SET_COMPANIES,
} from "../../actionTypes";

const initialState = {
  companies: [],
  companiesLoad: false,
};

export const companyReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_COMPANIES_START:
      return {
        ...state,
        companiesLoad: true,
      };
    case FETCH_COMPANIES_END:
      return {
        ...state,
        companiesLoad: false,
      };
    case SET_COMPANIES: {
      return {
        ...state,
        companies: payload,
      };
    }
    default:
      return state;
  }
};
