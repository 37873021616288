import * as React from "react";

const AddNote = (props) => (
  <svg
    width={18}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M.009 18 18 9 .009 0 0 7l12.857 2L0 11l.009 7Z" fill="#2F80ED" />
  </svg>
);

export default AddNote;
