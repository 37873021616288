import {
  FETCH_PROJECTS_START,
  FETCH_PROJECTS_END,
  SET_PROJECTS,
  FETCH_PROJECT_START,
  FETCH_PROJECT_END,
  SET_PROJECT,
} from "../../actionTypes/index";
import api from "../../../apiSingleton";
import { getDashboardData } from "../dashboard";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

export function getProjects(params) {
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_PROJECTS_START,
      });

      const data = await api.projects.getProjects(params);

      dispatch({
        type: SET_PROJECTS,
        payload: data,
      });
    } catch (error) {
      //console.log(error);
    } finally {
      dispatch({
        type: FETCH_PROJECTS_END,
      });
    }
  };
}

export function getProject(id) {
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_PROJECT_START,
      });

      const data = await api.projects.getProject(id);

      dispatch({
        type: SET_PROJECT,
        payload: data,
      });
    } catch (error) {
      //console.log(error);
    } finally {
      dispatch({
        type: FETCH_PROJECT_END,
      });
    }
  };
}

export function submitProject(id) {
  return async (dispatch) => {
    try {
      await api.projects.submitProject(id);
      await dispatch(getDashboardData());
    } catch (error) {
      //console.log(error);
    }
  };
}

export function exportProject(project) {
  return async (dispatch) => {
    try {
      const replaceChars = (string) => {
        return string.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "_");
      };

      // console.log(project)
      let company = await api.companies.getCompany(project.companyId);
      // console.log(company)

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const exportToCSV = (rows, fileName, sheetName) => {
        const worksheet = XLSX.utils.json_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const data = new Blob([excelBuffer], { type: fileType });
        console.log(rows);
        FileSaver.saveAs(data, fileName + fileExtension);
      };

      let rows = [];
      if (project.data.status === "In Preparation for Submission") {
        rows = project.documents.map((item) => {
          // console.log(item)
          return {
            Company: company.submission.data.name,
            Project: project.data.name,
            "Document Id": item.data.documentId,
            "Rev Number Id": item.data.revNumberId,
            "Document Name": item.data.name,
            "Short Name": item.data.shortName,
            "Document Type": item.data.documentType,
            "Release Date": item.data.releaseDate,
            Owner:
              item.data.owner !== undefined ? item.data.owner.data.name : "",
            Status: item.data.status,
          };
        });
        await exportToCSV(
          rows,
          replaceChars(
            `${company.submission.data.name}_${project.data.name}_documents`
          ),
          replaceChars(project.data.name)
        );
      } else if (
        project.data.status === "Agency Review" ||
        project.data.status === "Responding to Agency"
      ) {
        rows = project.findings.map((item) => {
          // console.log(item)
          return {
            Company: company.submission.data.name,
            Project: project.data.name,
            "Finding Number": item.data.findingNumber,
            Round: item.data.round,
            "Finding Name": item.data.name,
            Document: item.data.document.data.name,
            "Due Date": item.data.dueDate,
            Owner:
              item.data.assignedTo !== undefined
                ? item.data.assignedTo.data.name
                : "",
            Status: item.data.status,
          };
        });
        await exportToCSV(
          rows,
          replaceChars(
            `${company.submission.data.name}_${project.data.name}_findings`
          ),
          replaceChars(project.data.name)
        );
      }
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };
}
export function exportExpense(project) {
  return async (dispatch) => {
    try {
      const replaceChars = (string) => {
        return string.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "_");
      };

      let expenses = await api.dashboard.getProjectExpenses(project._id);

      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const exportToCSV = (rows, fileName, sheetName) => {
        const worksheet = XLSX.utils.aoa_to_sheet(rows);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
      };

      let rows = [];
      rows.push(["Project Name:", expenses.data.name]);
      rows.push(["Client Name:", expenses.data.company.data.name]);

      if ("totalExpensesForDocuments" in expenses.data) {
        rows.push([]);
        let object = expenses.data.totalExpensesForDocuments;
        // Totals
        var row = [
          "",
          "Totals",
          object.totalBudget,
          object.totalToDates,
          object.totalProgress,
        ];
        object.arrayOfMonths.forEach((item) => {
          row.push(object.totalPerMonths[item.fieldId]);
        });
        rows.push(row);
        // Header
        row = [
          "Document ID",
          "Document Name",
          "Budget",
          "Total to date",
          "Progress",
        ];
        object.arrayOfMonths.forEach((item) => {
          row.push(item.headerName);
        });
        rows.push(row);
        // Documents
        expenses.data.documents.forEach((document) => {
          row = [
            document._id,
            document.data.name,
            document.expenses.data.budget,
            document.expenses.data.totalToDate,
            document.expenses.data.progress,
          ];
          object.arrayOfMonths.forEach((item) => {
            if (item.fieldId in document.expenses.data.pricePerMonths) {
              row.push(document.expenses.data.pricePerMonths[item.fieldId]);
            } else {
              row.push("");
            }
          });
          rows.push(row);
        });

        await exportToCSV(
          rows,
          replaceChars(
            `${expenses.data.company.data.name}_${expenses.data.project.data.name}_expenses`
          ),
          replaceChars(expenses.data.project.data.name)
        );
      } else {
        alert("Expenses are not available.");
      }
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };
}
