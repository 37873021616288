import {Formio} from "@formio/react";

const LocalEnvConfig = {
    apiUrl: "http://localhost:3001",
};

const DevEnvConfig = {
  apiUrl: "https://middleware.dev.tracker.globalrto.com",
};

const ProdEnvConfig = {
  apiUrl: "https://middleware.tracker.globalrto.com",
};

export const AppEnvironments = {
    LOCAL: "local",
    DEVELOPMENT: "dev",
    PRODUCTION: "prod",
  }, AppConfig = ((env) => {
    switch (env) {
        case AppEnvironments.LOCAL:
            return LocalEnvConfig
        case AppEnvironments.DEVELOPMENT:
            return DevEnvConfig
        case AppEnvironments.PRODUCTION:
            return ProdEnvConfig
        default:
          return DevEnvConfig
    }
})(process.env.REACT_APP_ENVIRONMENT);

Formio.setBaseUrl(AppConfig.apiUrl);
Formio.setProjectUrl(AppConfig.apiUrl);
