import React, { useCallback, useEffect, useState } from "react";
import "formiojs/dist/formio.builder.min.css";
import { SimpleDisplayGrid } from "../../SimpleDisplayGrid";
import Box from "@mui/material/Box";
import { AppConfig } from "../../../config";
import Button from "@mui/material/Button";
import axios from "axios";
import { Auth } from "../../../modules/auth";

const columns = [
  {
    // Necessary for edit/delete
    field: "_id",
    headerName: "Id",
    hide: true,
    valueGetter: (params) => {
      return params.row._id;
    },
  },
  {
    field: "data.name",
    headerName: "Name",
    sortable: true,
    flex: 1,
    renderCell: (params) => {
      const projectRole = params.row.projectRole || "";
      const userName = params.row.data.name;
      const userRole = `${userName} - ${projectRole}`;
      const name = projectRole ? userRole : userName;
      return <div className="header_column">{name}</div>;
    },
  },
  {
    field: "data.email",
    headerName: "Email",
    sortable: true,
    flex: 1,
    valueGetter: (params) => {
      return params.row.data.email;
    },
  },
  {
    field: "data.role",
    headerName: "Role",
    sortable: true,
    width: 130,
    valueGetter: (params) => {
      return params.row.data.role;
    },
  },
  {
    field: "data.status",
    headerName: "Status",
    sortable: true,
    width: 80,
    align: "center",
    valueGetter: (params) => {
      return params.row.data.status;
    },
  },
  {
    field: "data.confirmed",
    headerName: "Confirmed",
    sortable: true,
    width: 150,
    align: "center",
    headerAlign: "center",
    renderCell: (params) => {
      const onResend = (e) => {
        e.stopPropagation(); // don't select this row after clicking

        const api = params.api;
        const thisRow = {};

        api
          .getAllColumns()
          .filter((c) => c.field !== "__check__" && !!c)
          .forEach(
            (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
          );

        const submission = {
          data: {
            _id: thisRow["_id"],
          },
        };
        const auth = Auth();
        axios
          .post(AppConfig.apiUrl + "/auth/invitation_email", submission, {
            headers: auth.getAuthHeaders(),
          })
          .then((response) => {
            if (response.status >= 200 && response.status < 300) {
              alert("Invitation sent!");
            }
          })
          .catch((error) => {
            alert("Failed to send invite.");
          });
      };

      return (
        <span>
          {params.row.data.confirmed === false && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ marginLeft: 16 }}
              onClick={onResend}
            >
              Resend Invite
            </Button>
          )}
          {params.row.data.confirmed === true && <span>Confirmed</span>}
        </span>
      );
    },
  },
  {
    field: "created",
    headerName: "Created on",
    sortable: true,
    width: 130,
  },
  {
    field: "modified",
    headerName: "Modified on",
    sortable: true,
    width: 130,
  },
];

export const AdminTable = ({
  pageTitle,
  addButtonTitle,
  tableData,
  fetchData,
  children,
}) => {
  return (
    <Box>
      <SimpleDisplayGrid
        url={AppConfig.apiUrl + "/admin"}
        addFormUrl={AppConfig.apiUrl + "/auth/registeradmin"}
        getEditFormUrl={AppConfig.apiUrl + "/admin/update"}
        editFormUrl={AppConfig.apiUrl + "/admin/update"}
        deleteFormUrl={AppConfig.apiUrl + "/admin/update"}
        columns={columns}
        buttonTitle={addButtonTitle}
        tableData={tableData}
        fetchData={fetchData}
        children={children}
      />
    </Box>
  );
};
