import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { FilterButton } from "../../components/PageFilters";
import { CompanyTable } from "../../components/Table/CompanyTable";
import { getCompanies } from "../../store/actions/company";
import { companiesSelector } from "../../store/selectors/company";
import { PageSearch } from "../../components/PageSearch";
import { useDebounce } from "../../hooks/useDebounce";
import "./style.css";

export const CompanyPage = () => {
  const buttons = [
    {
      id: 1,
      value: "All Organizations",
    },
    {
      id: 2,
      value: "Active Organizations",
    },
    {
      id: 3,
      value: "Archived Organizations",
    },
  ];
  const dispatch = useDispatch();
  const [filteredValue, setFilteredValue] = useState("All Organizations");
  const [searchValue, setSearchValue] = useState("");
  const [tableData, setData] = useState([]);
  const debouncedValue = useDebounce(searchValue);
  const companiesData = useSelector(companiesSelector);

  useEffect(() => {
    fetchCompanies();
  }, [filteredValue]);

  const fetchCompanies = async () => {
    const queryParams = getQueryParam(filteredValue);
    dispatch(getCompanies(queryParams));
  };

  const handleSearch = (e) => {
    const value = e.target.value;

    setSearchValue(value);
  };

  const filterBySearchQuery = (data = []) => {
    return data?.filter((item) => {
      const FILTERED_FIELDS = [item.data.name];

      if (!debouncedValue) {
        return true;
      }
      return FILTERED_FIELDS.some((field) =>
        field.toLowerCase().includes(debouncedValue.toLowerCase())
      );
    });
  };

  useEffect(() => {
    const filteredData = filterBySearchQuery(companiesData);
    setData(filteredData);
  }, [debouncedValue, companiesData]);

  const getQueryParam = (value) => {
    switch (value) {
      case "All Organizations":
        return {};
      case "Active Organizations":
        return { status: "active" };
      case "Archived Organizations":
        return { status: "archived" };
      default:
        return {};
    }
  };

  return (
    <div>
      <Grid
        container
        justifyContent="space-between"
        style={{
          alignItems: "center",
          paddingTop: "16px",
          paddingLeft: "24px",
          paddingRight: "24px",
        }}
      >
        <Grid item xs={9}>
          <Grid container flexDirection="column">
            <Grid item>
              <Breadcrumbs>
                <Typography style={{ fontSize: "12px" }}>Dashboard</Typography>
                <Typography style={{ fontSize: "12px", color: "black" }}>
                  Organization Management
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item style={{ marginBottom: "16px" }}>
              <Typography style={{ fontSize: "24px", fontWeight: "600" }}>
                Organization Management
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Grid
        container
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0px 24px ",
        }}
      >
        <Grid item>
          <PageSearch value={searchValue} handleSearch={handleSearch} />
        </Grid>

        <Grid item>
          <FilterButton
            buttons={buttons}
            filter={filteredValue}
            changeFilter={setFilteredValue}
          />
        </Grid>
        <Grid
          item
          style={{
            marginTop: "-31px",
          }}
        >
          <CompanyTable
            pageTitle={"Organization"}
            addButtonTitle={"Add Organization"}
            tableData={tableData}
            fetchData={fetchCompanies}
          />
        </Grid>
      </Grid>
    </div>
  );
};
