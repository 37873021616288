import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LoginLayout } from "./layout/LoginLayout";
import { LoginPage } from "./pages/Login";
import { CompanyPage } from "./pages/CompanyManagement";
import { AgencyPage } from "./pages/Agency";
import { ProjectManagementPage } from "./pages/ProjectManagement";
import { UserManagementPage } from "./pages/UserManagement";
import { DashboardPage } from "./pages/Dashboard";
import { RegistrationPage } from "./pages/Registration";
import { DocumentTabLayoutPage } from "./pages/Documents";
import { FindingTabLayoutPage } from "./pages/Findings";

import { ROUTES } from "./router";
import "bootstrap/dist/css/bootstrap.min.css";

import "./App.css";
import ProtectedRoute from "./modules/auth/ProtectedRoute";
import { AdminManagementPage } from "./pages/AdminManagement";
import { AccountConfirmationPage } from "./pages/PasswordManagement/accountConfirmation";
import { PasswordResetPage } from "./pages/PasswordManagement/passwordReset";
import { ForgotPasswordPage } from "./pages/PasswordManagement/forgotPassword";
import { setSessionData } from "./store/actions/session";

function App() {
  const dispatch = useDispatch();

  return (
    <Routes>
      <Route path={ROUTES.ROOT} element={<LoginLayout />}>
        <Route index element={<LoginPage />} />
        <Route path={ROUTES.REGISTRATION} element={<RegistrationPage />} />
      </Route>
      <Route path={ROUTES.DASHBOARD} element={<ProtectedRoute />}>
        <Route path={ROUTES.DASHBOARD} element={<DashboardPage />} />
        <Route path={ROUTES.COMPANY_MANAGEMENT} element={<CompanyPage />} />
        <Route path={ROUTES.AGENCY} element={<AgencyPage />} />
        <Route
          path={ROUTES.PROJECT_MANAGEMENT}
          element={<ProjectManagementPage />}
        />
        <Route path={ROUTES.DOCUMENT_MANAGEMENT} element={<DocumentTabLayoutPage />} />
        <Route path={ROUTES.FINDING_MANAGEMENT} element={<FindingTabLayoutPage />} />
        <Route path={ROUTES.USER_MANAGEMENT} element={<UserManagementPage />} />
        <Route
          path={ROUTES.ADMIN_MANAGEMENT}
          element={<AdminManagementPage />}
        />
      </Route>
      <Route
        path={ROUTES.ACCOUNT_CONFIRMATION}
        element={<AccountConfirmationPage />}
      />
      <Route path={ROUTES.RESET_PASSWORD} element={<PasswordResetPage />} />
      <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
    </Routes>
  );
}

export default App;
