import {
  FETCH_EXPENSES_START,
  FETCH_EXPENSES_END,
  SET_EXPENSES,
  CLEAR_EXPENSES,
} from "../../actionTypes/index";
import api from "../../../apiSingleton";

export function getExpenses(projectId) {
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_EXPENSES_START,
      });

      const response = await api.expenses.getExpenses(projectId);

      dispatch({
        type: SET_EXPENSES,
        payload: response.data,
      });
    } catch (error) {
      //console.log(error);
    } finally {
      dispatch({
        type: FETCH_EXPENSES_END,
      });
    }
  };
}

export function createExpenses(data) {
  return async (dispatch) => {
    try {
      await api.expenses.createExpenses(data);
    } catch (error) {
      //console.log("Create document", error);
    }
  };
}
export function updateExpenses(data) {
  return async (dispatch) => {
    try {
      await api.expenses.updateExpenses(data);
    } catch (error) {
      //console.log("Create document", error);
    }
  };
}

export function clearCurentExpenses() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_EXPENSES,
    });
  };
}
