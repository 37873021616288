import api from "../../../apiSingleton";
import { getDashboardData } from "../dashboard";

export function createNote(data) {
  return async (dispatch) => {
    try {
      await api.notes.createNote({ data });
      await dispatch(getDashboardData());
    } catch (error) {}
  };
}

export function deleteNote(id) {
  return async (dispatch) => {
    try {
      await api.notes.deleteNote(id);
    } catch (error) {
      //console.log(error);
    }
  };
}
