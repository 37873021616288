import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import {
  ModalContainer,
  ModalContent,
  ModalHeader,
} from "../../ui-components/ModalConstructor";
import { FormRenderer } from "../../Form/FormRender";
import { AppConfig } from "../../../config";
import { useState } from "react";
import { createFinding, updateFinding } from "../../../store/actions/finding";
import { getDashboardData } from "../../../store/actions/dashboard";

export const FindingModal = ({
  onClose,
  title,
  description,
  topic,
  width = "500px",
  mode,
  findingId,
  companyId,
  projectId,
  ref,
}) => {
  const dispatch = useDispatch();
  const [findingUrl, setFindingUrl] = useState("");

  useEffect(() => {
    if (mode === "EDIT") {
      setFindingUrl(`${AppConfig.apiUrl}/finding/submission/${findingId}`);
    } else {
      const params = {
        companyId,
        projectId,
      };
      const query = `?${queryString.stringify(params)}`;
      setFindingUrl(`${AppConfig.apiUrl}/finding${query}`);
    }
  }, [mode, findingId, projectId, companyId]);

  const handleCreateFinding = async (data) => {
    try {
      await dispatch(createFinding(data));
      // await dispatch(getDashboardData());
      onClose();
    } catch (error) {
      //console.log(error);
    }
  };

  const hundleUpdateFinding = async (data) => {
    try {
      await dispatch(updateFinding(data, findingId));
      // await dispatch(getDashboardData());
      onClose();
    } catch (error) {
      //console.log(error);
    }
  };

  const onSubmit = (submission) => {
    if (mode === "CREATE") {
      handleCreateFinding(submission);
    }

    if (mode === "EDIT") {
      hundleUpdateFinding(submission);
    }
  };

  return (
    <ModalContainer width={width} onClose={onClose} ref={ref}>
      <ModalHeader
        onClose={onClose}
        title={title}
        description={description}
        topic={topic}
        type="ITEM"
      />
      <ModalContent>
        <FormRenderer url={findingUrl} onSubmit={onSubmit} />
      </ModalContent>
    </ModalContainer>
  );
};
