import React from "react";
import "formiojs/dist/formio.builder.min.css";
import { SimpleDisplayGrid } from "../../SimpleDisplayGrid";
import Box from "@mui/material/Box";
import { AppConfig } from "../../../config";
import { renderTableStatus } from "../utils";
import "../general_css/style.css";

const columns = [
  {
    // Necessary for edit/delete
    field: "_id",
    headerName: "Id",
    hide: true,
    valueGetter: (params) => {
      return params.row._id;
    },
  },
  {
    field: "data.name",
    headerName: "Name",
    sortable: true,
    flex: 1,
    valueGetter: (params) => {
      return params.row.data.name;
    },
  },
  {
    field: "data.owner.id",
    headerName: "Owner",
    sortable: true,
    flex: 1,
    valueGetter: (params) => {
      return params.row.data.owner._id;
    },
  },
  {
    field: "data.status",
    headerName: "Status",
    sortable: true,
    flex: 1,
    renderCell: (params) => {
      const status = params.row.data.status;
      return renderTableStatus(status, "PROJECT");
    },
  },
  {
    field: "data.owner.id",
    headerName: "Owner",
    sortable: true,
    flex: 1,
    valueGetter: (params) => {
      return params.row.data.owner.data.name;
    },
  },
  {
    field: "data.company.id",
    headerName: "Organization",
    sortable: true,
    flex: 1,
    valueGetter: (params) => {
      return params.row.data.company.data.name;
    },
  },
  {
    field: "created",
    headerName: "Created on",
    sortable: true,
    flex: 1,
  },
  {
    field: "modified",
    headerName: "Modified on",
    sortable: true,
    flex: 1,
  },
];

export const ProjectTable = ({
  addButtonTitle,
  tableData,
  fetchData,
  children,
}) => {
  return (
    <Box>
      <SimpleDisplayGrid
        url={AppConfig.apiUrl + "/project/available"}
        addFormUrl={AppConfig.apiUrl + "/project"}
        getEditFormUrl={AppConfig.apiUrl + "/project"}
        editFormUrl={AppConfig.apiUrl + "/project"}
        deleteFormUrl={AppConfig.apiUrl + "/project"}
        columns={columns}
        buttonTitle={addButtonTitle}
        tableData={tableData}
        fetchData={fetchData}
        children={children}
      />
    </Box>
  );
};
