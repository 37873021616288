import React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import classNames from "classnames";
import "./style.css";

function Component({ buttons, filter, changeFilter }) {
  const handleChangeFilter = (e) => {
    changeFilter(e.target.value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      {buttons.map((button) => {
        return (
          <Button
            className={classNames({
              button: true,
              active: button.value === filter,
            })}
            onClick={handleChangeFilter}
            key={button.id}
            value={button.value}
          >
            {button.value}
          </Button>
        );
      })}
    </Box>
  );
}

export const FilterButton = React.memo(Component);
