import ApiClient from "./ApiClient";
import UserApi from "./User";
import CompanyApi from "./Company";
import AdminApi from "./Admin";
import ProjectsApi from "./Project";
import DocumentsApi from "./Document";
import DashboardApi from "./Dashboard";
import NotesApi from "./Note";
import FindingsApi from "./Finding";
import ReminderApi from "./Reminder";
import ExpensesApi from "./Expenses";
import LinkApi from "./Link";

export default function makeApiList({ apiPrefix } = {}) {
  if (!apiPrefix) {
    throw new Error("[apiPrefix] required");
  }

  const api = new ApiClient({ prefix: apiPrefix });

  return {
    apiClient: api,
    users: new UserApi({ apiClient: api }),
    companies: new CompanyApi({ apiClient: api }),
    admins: new AdminApi({ apiClient: api }),
    projects: new ProjectsApi({ apiClient: api }),
    documents: new DocumentsApi({ apiClient: api }),
    dashboard: new DashboardApi({ apiClient: api }),
    notes: new NotesApi({ apiClient: api }),
    findings: new FindingsApi({ apiClient: api }),
    reminders: new ReminderApi({ apiClient: api }),
    expenses: new ExpensesApi({ apiClient: api }),
    link: new LinkApi({ apiClient: api }),
  };
}
