import Base from "./Base";

export default class DashboardApi extends Base {
  getDashboardData(params) {
    return this.apiClient.get("dashboard/search", params);
  }
  getProjectExpenses(id) {
    return this.apiClient.get(`dashboard/projectExpenses/${id}`);
  }
  getCostExpenses(id) {
    return this.apiClient.get(`cost_expenses/${id}`);
  }
}
