import api from "../../../apiSingleton";
import { getDashboardData } from "../dashboard";

export function addLink(data) {
  return async (dispatch) => {
    try {
      await api.link.addLink({ data });
      await dispatch(getDashboardData());
    } catch (error) {}
  };
}

export function deleteLink(id) {
  return async (dispatch) => {
    try {
      await api.link.deleteLink(id);
      await dispatch(getDashboardData());
    } catch (error) {
      //console.log(error);
    }
  };
}
