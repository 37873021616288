import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "formiojs/dist/formio.builder.min.css";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Grid, Modal } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { dumpData } from "../../utils/prepareData";
import { FormRenderer } from "../Form/FormRender";
import { CustomButton } from "../ui-components/Button";
import "./style.css";
import { Auth } from "../../modules/auth";
import { errorResponseProcessor } from "../../utils/responseProcessor";

export const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const MODES = {
  NONE: 0,
  FORM: 1,
  ADD: 2,
  EDIT_FORM: 3,
  EDIT: 4,
  DELETE: 5,
};

export const SimpleDisplayGrid = ({
  url,
  addFormUrl,
  getEditFormUrl,
  editFormUrl,
  deleteFormUrl,
  columns,
  buttonTitle,
  tableData,
  agencyStyle,
  fetchData,
  disabledAddbutton = false,
  children,
}) => {
  const dispatch = useDispatch();

  const [mode, setMode] = useState(MODES.NONE);
  const [selectedId, setSelectedId] = useState("");
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = (mode) => {
    setMode(mode);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const auth = Auth();

  useConstructor(() => {
    columns.push({
      field: "actions",
      headerName: "Actions",
      sortable: false,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      width: 120,
      renderCell: (params) => {
        const onEditClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          const api = params.api;
          const thisRow = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );

          setSelectedId(thisRow["_id"]);
          handleOpen(MODES.EDIT_FORM);
        };

        const onDeleteClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          const api = params.api;
          const thisRow = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );

          axios
            .delete(getURL(MODES.DELETE, thisRow["_id"]), {
              headers: auth.getAuthHeaders(),
            })
            .then((response) => {
              if (response.status === 200) {
                setReload(true);
                fetchData();
              }
            });
        };

        return (
          <Box>
            <IconButton
              aria-label="edit"
              sx={{ mr: 1 }}
              onClick={onEditClick}
              disabled={disabledAddbutton}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              aria-label="delete"
              onClick={onDeleteClick}
              disabled={disabledAddbutton}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        );
      },
    });
  });

  useEffect(() => {
    if (!tableData) {
      axios.get(url, { headers: auth.getAuthHeaders() }).then((response) => {
        const newData = dumpData(response.data);
        setData(newData);
        // setResponse(response);
      });
    } else {
      setData(dumpData(tableData));
    }

    return () => {
      setReload(false);
    };
  }, [reload, url, tableData]);

  const getRowId = (row) => {
    return row["_id"];
  };

  const onSubmit = (submission, form) => {
    form.current.checkData();

    let _url = getURL(mode);
    let request = null;
    if (mode === MODES.EDIT_FORM) {
      _url = getURL(MODES.EDIT);
      request = axios.put(_url, submission, { headers: auth.getAuthHeaders() });
    } else if (mode === MODES.FORM) {
      _url = getURL(MODES.ADD);
      request = axios.post(_url, submission, {
        headers: auth.getAuthHeaders(),
      });
    }
    request
      .then((response) => {
        handleClose();
        setReload(true);
        fetchData();
      })
      .catch((error) => {
        errorResponseProcessor(error, form);
      });
  };

  const getURL = (currentMode, currentId = "") => {
    switch (currentMode) {
      case MODES.NONE:
      case MODES.FORM:
        return addFormUrl;
      case MODES.ADD:
        return addFormUrl + "/submission";
      case MODES.EDIT_FORM:
        return getEditFormUrl + "/submission/" + selectedId;
      case MODES.EDIT:
        return editFormUrl + "/submission/" + selectedId;
      case MODES.DELETE:
        return deleteFormUrl + "/submission/" + currentId;
      default:
        return url;
    }
  };
  const [pageSize, setPageSize] = useState(15);
  return (
    <div style={{ ...agencyStyle, display: "flex", height: "100%" }}>
      <div style={{ flexGrow: 1 }}>
        <Grid
          container
          justifyContent="space-between"
          style={{
            marginBottom: "24px",
          }}
        >
          <Grid
            item
            style={{
              margin: "24px 0px 0px 24px ",
            }}
          >
            {/* <PageSearch /> */}
          </Grid>
          <Grid
            item
            style={{
              margin: "0px 24px",
            }}
          >
            {!disabledAddbutton && (
              <CustomButton
                onClick={() => handleOpen(MODES.FORM)}
                title={buttonTitle}
                style={{
                  marginTop: "24px",
                }}
              />
            )}
          </Grid>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={style}
              style={{
                maxHeight: "600px",
                maxWidth: "600px",
                overflow: "auto",
              }}
            >
              <FormRenderer url={getURL(mode)} onSubmit={onSubmit} />
            </Box>
          </Modal>
        </Grid>

        <DataGrid
          rows={data}
          columns={columns}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[5, 10, 15, 25, 50, 100]}
          pagination
          {...data}
          checkboxSelection={false}
          disableSelectionOnClick
          getRowId={getRowId}
          autoHeight
          className="pagination"
        />
      </div>
    </div>
  );
};

SimpleDisplayGrid.defaultProps = {
  fetchData: () => {},
};
