import * as React from "react";

const AddDocument = (props) => (
  <svg
    width={45}
    height={45}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={45} height={45} rx={12} fill="#2F80ED" fillOpacity={0.1} />
    <path
      d="M32.5 11.5h-20v22h20v-22ZM25.833 16.5h2.223M25.833 20.5h2.223M16.944 24.5h11.111M16.944 28.5h11.111"
      stroke="#2F80ED"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M21.389 16.5h-4.445v4h4.445v-4Z"
      stroke="#2F80ED"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);

export default AddDocument;
