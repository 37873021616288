import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export const CustomButton = ({ onClick, title, startIcon }) => {
  return (
    <Button
      variant="contained"
      startIcon={startIcon}
      onClick={onClick}
      style={{
        backgroundColor: "#333333",
        height: "32px",
      }}
    >
      {title}
    </Button>
  );
};

CustomButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
};

CustomButton.defaultProps = {
  onClick: () => {},
  title: "",
  startIcon: <AddCircleOutlineIcon />,
};
