export const errorResponseProcessor = (error, form) => {
  let message = undefined;
  try {
    message = JSON.parse(error.response.data.message);
  } catch {
    message = error.response.data.message;
  }
  let _message = "Form has following errors: <br/>";
  if (message.name === "ValidationError") {
    const details = message.details;
    details.forEach((detail) => {
      _message += "  - " + detail.message + "<br/>";
    });
  } else if (typeof message === "object") {
    if (Array.isArray(message)) {
      message.forEach((item) => {
        _message += "  - " + item + "<br/>";
      });
    } else {
      _message += "  - " + JSON.stringify(message);
    }
  } else if (typeof message === "string") {
    _message += "  - " + message;
  }
  form.current.setAlert("alert alert-danger", _message);
  form.current.emit("error");
  form.current.emit("submitError");
};
