import React from "react";
import "formiojs/dist/formio.builder.min.css";
import { SimpleDisplayGrid } from "../../SimpleDisplayGrid";
import Box from "@mui/material/Box";
import { columns } from "./company_columns";
import { AppConfig } from "../../../config";

export const CompanyTable = ({
  pageTitle,
  addButtonTitle,
  tableData,
  fetchData,
  children,
}) => {
  return (
    <Box>
      <SimpleDisplayGrid
        url={AppConfig.apiUrl + "/company/available"}
        addFormUrl={AppConfig.apiUrl + "/company"}
        getEditFormUrl={AppConfig.apiUrl + "/company"}
        editFormUrl={AppConfig.apiUrl + "/company"}
        deleteFormUrl={AppConfig.apiUrl + "/company"}
        columns={columns}
        buttonTitle={addButtonTitle}
        tableData={tableData}
        fetchData={fetchData}
        children={children}
      />
    </Box>
  );
};
