import api from "../../../apiSingleton";
import { getDashboardData } from "../dashboard";

export function createReminder(data) {
  return async (dispatch) => {
    try {
      await api.reminders.createReminder({ data });
      dispatch(getDashboardData());
    } catch (error) {
      //console.log("create reminder", error);
    }
  };
}
