import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { ProjectTable } from "../../components/Table/ProjectTable";
import { FilterButton } from "../../components/PageFilters";

import { projectsSelector } from "../../store/selectors/project";
import { getProjects } from "../../store/actions/project";
import { Auth } from "../../modules/auth";
import { PageSearch } from "../../components/PageSearch";
import { useDebounce } from "../../hooks/useDebounce";
import "./style.css";

export const ProjectManagementPage = () => {
  const auth = Auth();

  const buttons = [
    {
      id: 1,
      value: "All Projects",
    },
    {
      id: 2,
      value: "In Preparation for Submission",
    },
    {
      id: 3,
      value: "Responding to Agency",
    },
    {
      id: 4,
      value: "Agency Review",
    },
    {
      id: 5,
      value: "Closed",
    },
    {
      id: 6,
      value: "My Projects",
    },
  ];

  const dispatch = useDispatch();
  const [filteredValue, setFilteredValue] = useState("All Projects");
  const [data, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const projectsData = useSelector(projectsSelector);
  const debouncedValue = useDebounce(searchValue);

  useEffect(() => {
    fetchProjects();
  }, [filteredValue]);

  useEffect(() => {
    const filteredData = filterBySearchQuery(projectsData);
    setData(filteredData);
  }, [debouncedValue, projectsData]);

  const fetchProjects = async () => {
    const queryParams = getQueryParam(filteredValue);
    dispatch(getProjects(queryParams));
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  const filterBySearchQuery = (data = []) => {
    return data?.filter((item) => {
      const FILTERED_FIELDS = [
        item.data.name,
        item.data.owner.data.name,
        item.data.company.data.name,
      ];

      if (!debouncedValue) {
        return true;
      }
      return FILTERED_FIELDS.some((field) => {
        if (!field) {
          return false;
        }

        return field.toLowerCase().includes(debouncedValue.toLowerCase());
      });
    });
  };

  const getQueryParam = (value) => {
    switch (value) {
      case "All Projects":
        return {};
      case "In Preparation for Submission":
        return { status: "In Preparation for Submission" };
      case "Responding to Agency":
        return { status: "Responding to Agency" };
      case "Agency Review":
        return { status: "Agency Review" };
      case "Closed":
        return { status: "Closed" };
      case "My Projects":
        return { ownerId: auth.getUser()._id };
      default:
        return {};
    }
  };

  return (
    <div>
      <Grid
        container
        justifyContent="space-between"
        style={{
          alignItems: "center",
          paddingTop: "16px",
          paddingLeft: "24px",
          paddingRight: "24px",
        }}
      >
        <Grid item xs={9}>
          <Grid container flexDirection="column">
            <Grid item>
              <Breadcrumbs>
                <Typography style={{ fontSize: "12px" }}>Dashboard</Typography>
                <Typography style={{ fontSize: "12px", color: "black" }}>
                  Project Management
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item style={{ marginBottom: "16px" }}>
              <Typography style={{ fontSize: "24px", fontWeight: "600" }}>
                Project Management
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />

      <Grid
        container
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0px 24px",
        }}
      >
        <Grid item>
          <PageSearch value={searchValue} handleSearch={handleSearch} />
        </Grid>
        <Grid item>
          <FilterButton
            buttons={buttons}
            filter={filteredValue}
            changeFilter={setFilteredValue}
          />
        </Grid>
        <Grid
          item
          style={{
            marginTop: "-31px",
          }}
        >
          <ProjectTable
            pageTitle={"Projects"}
            addButtonTitle={"Add Project"}
            tableData={data}
            fetchData={fetchProjects}
          />
        </Grid>
      </Grid>
    </div>
  );
};
