import Base from "./Base";

export default class DocumentsApi extends Base {
  getDocuments(params) {
    return this.apiClient.get("document/available", params);
  }
  deleteDocument(id) {
    return this.apiClient.delete(`document/submission/${id}`);
  }
  createDocument(body) {
    return this.apiClient.post("document/submission", body);
  }
  updateDocument(body, id) {
    return this.apiClient.put(`document/submission/${id}`, body);
  }
}
