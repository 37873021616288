import Base from "./Base";

export default class LinkApi extends Base {
  addLink(body) {
    return this.apiClient.post("link/submission", body);
  }
  deleteLink(id) {
    return this.apiClient.delete(`link/submission/${id}`);
  }
}
