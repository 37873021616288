import React from "react";
import Reminder from "../../Icons/Reminder";
import moment from "moment";
import { formatDate } from "../../../utils/formatDate";

export const ReminderInfo = ({ reminders }) => {
  const curentReminder = reminders[0] || [];
  const reminderDate = curentReminder?.data?.date;
  const dateNow = new Date();

  const dateIsAfter = moment(dateNow).isAfter(moment(reminderDate));

  if (!reminders || dateIsAfter) {
    return null;
  }

  return reminders.length ? (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        height: "74px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Reminder />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "15px",
        }}
      >
        <span
          style={{
            color: "#333333",
            fontSize: "16px",
            fontWeight: 600,
          }}
        >{`Reminder set for ${formatDate(reminderDate, "l")}`}</span>
        <span
          style={{
            fontWeight: 500,
            fontSize: "14px",
            color: "#828282",
          }}
        >
          "{`${curentReminder?.data?.name}`}"
        </span>
      </div>
    </div>
  ) : null;
};
