import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import Clear from "@material-ui/icons/Clear";
import { renderIcon } from "./utils";
import "./style.css";

export const ModalHeader = ({
  title,
  description,
  topic,
  type,
  align,
  onClose,
  closeButton = true,
  divider = true,
}) => {
  return (
    <div
      data-testid={"title"}
      align={align}
      variant="h6"
      className={classnames({
        madalHeader: true,
        headerDivider: divider,
      })}
    >
      <div className="modal_header_wrapper">
        <div className="modal_icon">{renderIcon(type)}</div>
        <div>
          <h6
            style={{
              fontWeight: 600,
              fontSize: "18px",
              lineHeight: "22px",
            }}
          >
            {title}
          </h6>
          <p className="modal_description">
            {topic && <span className="topic">{`${topic}:`}</span>}
            <span>{description}</span>
          </p>
        </div>
      </div>
      {closeButton && (
        <span data-testid={"close"} className="close" onClick={onClose}>
          <Clear className="close_button" />
        </span>
      )}
    </div>
  );
};

ModalHeader.propTypes = {
  title: PropTypes.string,
  align: PropTypes.string,
  onClose: PropTypes.func,
  closeButton: PropTypes.bool,
  divider: PropTypes.bool,
  key: PropTypes.string,
};

ModalHeader.defaultProps = {
  title: "",
  align: "left",
  onClose: () => {},
  closeButton: true,
  divider: true,
  key: "",
};
