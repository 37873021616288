import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { MainLayout } from "../../../layout/MainLayout";
import { Auth } from "../../auth";

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = Auth().isAuthenticated();
  
  return isAuthenticated === "true" ? (
    <MainLayout>{children}</MainLayout>
  ) : (
    <Navigate to="/" />
  );
};

export default ProtectedRoute;
