import React from "react";

const DashboardIcon = (props) => (
  <svg width={18} height={18} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.364 10.5H.818C.367 10.5 0 10.164 0 9.75v-9C0 .336.367 0 .818 0h6.546c.451 0 .818.336.818.75v9c0 .414-.367.75-.818.75ZM7.364 18H.818C.367 18 0 17.664 0 17.25v-4.5c0-.414.367-.75.818-.75h6.546c.451 0 .818.336.818.75v4.5c0 .414-.367.75-.818.75ZM17.182 6h-6.546c-.451 0-.818-.336-.818-.75V.75c0-.414.367-.75.818-.75h6.546c.451 0 .818.336.818.75v4.5c0 .414-.367.75-.818.75ZM17.182 18h-6.546c-.451 0-.818-.336-.818-.75v-9c0-.414.367-.75.818-.75h6.546c.451 0 .818.336.818.75v9c0 .414-.367.75-.818.75Z"
      fill={props.color}
    />
  </svg>
);

export default DashboardIcon;
