import React from "react";
import "formiojs/dist/formio.builder.min.css";
import { SimpleDisplayGrid } from "../../SimpleDisplayGrid";
import Box from "@mui/material/Box";
import { AppConfig } from "../../../config";

const columns = [
  {
    // Necessary for edit/delete
    field: "_id",
    headerName: "Id",
    hide: true,
    valueGetter: (params) => {
      return params.row._id;
    },
  },
  {
    field: "data.name",
    headerName: "Agency Name",
    sortable: true,
    flex: 1,
    valueGetter: (params) => {
      return params.row.data.name;
    },
  },
  {
    field: "created",
    headerName: "Created on",
    sortable: true,
    flex: 1,
  },
  {
    field: "modified",
    headerName: "Modified on",
    sortable: true,
    flex: 1,
  },
];

const style = { marginTop: "24px" };

export const AgencyTable = ({ pageTitle, addButtonTitle, children }) => {
  return (
    <Box sx={{ ml: "24px",  mr: "24px" }}>
      <SimpleDisplayGrid
        url={AppConfig.apiUrl + "/agency/available"}
        addFormUrl={AppConfig.apiUrl + "/agency"}
        getEditFormUrl={AppConfig.apiUrl + "/agency"}
        editFormUrl={AppConfig.apiUrl + "/agency"}
        deleteFormUrl={AppConfig.apiUrl + "/agency"}
        columns={columns}
        buttonTitle={addButtonTitle}
        agencyStyle={style}
        children={children}
      />
    </Box>
  );
};
