import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import { FilterButton } from "../../components/PageFilters";
import { DocumentTable } from "../../components/Table/DocumentTable";
import { PageSearch } from "../../components/PageSearch";
import { useDebounce } from "../../hooks/useDebounce";
import { getDocuments } from "../../store/actions/document";
import { documentsSelector } from "../../store/selectors/document";
import "./style.css";

export const DocumentPage = ({ pageTitle }) => {
  const buttons = [
    {
      id: 1,
      value: "All Documents",
    },
  ];

  const dispatch = useDispatch();
  const [tableData, setData] = useState([]);

  const [filteredValue, setFilteredValue] = useState("All Documents");
  const [searchValue, setSearchValue] = useState("");
  const documentData = useSelector(documentsSelector);
  const debouncedValue = useDebounce(searchValue);

  const fetchDocuments = async () => {
    dispatch(getDocuments());
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  const handleSearch = (e) => {
    const value = e.target.value;

    setSearchValue(value);
  };

  const filterBySearchQuery = (data = []) => {
    return data?.filter((item) => {
      const FILTERED_FIELDS = [item.data.name, item.data.company.data.name];

      if (!debouncedValue) {
        return true;
      }
      return FILTERED_FIELDS.some((field) =>
        field.toLowerCase().includes(debouncedValue.toLowerCase())
      );
    });
  };

  useEffect(() => {
    const filteredData = filterBySearchQuery(documentData);

    setData(filteredData);
  }, [debouncedValue, documentData]);

  return (
    <Grid
    container
    style={{
        display: "flex",
        flexDirection: "column",
        padding: "0px 24px",
    }}
    >
        <Grid item>
            <PageSearch value={searchValue} handleSearch={handleSearch} />
        </Grid>
        <Grid item>
            <FilterButton
            buttons={buttons}
            filter={filteredValue}
            changeFilter={setFilteredValue}
            />
        </Grid>
        <Grid
            item
            style={{
            marginTop: "-31px",
            }}
        >
            <DocumentTable
            pageTitle={"Document"}
            addButtonTitle={"Add Document"}
            tableData={tableData}
            fetchData={fetchDocuments}
            />
        </Grid>
    </Grid>
  );
};
