import Base from "./Base";

export default class FindingsApi extends Base {
  getFindings(params) {
    return this.apiClient.get("finding/available", params);
  }
  deleteFinding(id) {
    return this.apiClient.delete(`finding/submission/${id}`);
  }
  createFinding(body) {
    return this.apiClient.post("finding/submission", body);
  }
  updateFinding(body, id) {
    return this.apiClient.put(`finding/submission/${id}`, body);
  }
}
