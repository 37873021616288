import { STATUS } from "../../../constants/status";

const classNames = require("classnames");

export const renderTableStatus = (status, type) => {
  const statusStyle = classNames({
    statusBlock: true,
    in_preparation: status === STATUS[type].IN_PREPARATION,
    responding: status === STATUS[type].RESPONDING,
    closed: status === STATUS[type].CLOSED,
    agency: status === STATUS[type].AGENCY,
    archived: status === STATUS[type].ARCHIVED,
    on_hold: status === STATUS[type].ON_HOLD,
    ready: status === STATUS[type].READY,
    submitted: status === STATUS[type].SUBMITTED,
  });
  return <div className={statusStyle}>{status}</div>;
};
