import PropTypes from "prop-types";

export function ModalContent({ children }) {
  return (
    <div
      style={{
        padding: "20px",
        maxHeight: "500px",
        overflow: "auto",
      }}
    >
      {children}
    </div>
  );
}

ModalContent.propTypes = {
  children: PropTypes.node,
};

ModalContent.defaultProps = {
  children: null,
};
