import React from "react";
import PropTypes from "prop-types";
import {
  ModalContainer,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "../../ui-components/ModalConstructor";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import AddNote from "../../Icons/AddNotes";
import { Notes } from "./Notes";

export const ShowNotesModal = ({
  onClose,
  onSubmit,
  title,
  description,
  topic,
  width = "500px",
  notes,
  value,
  onChange,
  showAddNotesInput,
  ref,
}) => {
  return (
    <ModalContainer width={width} onClose={onClose} ref={ref}>
      <ModalHeader
        onClose={onClose}
        title={title}
        description={description}
        topic={topic}
        type="NOTES"
      />
      <ModalContent>
        <Notes notes={notes} />
      </ModalContent>
      {showAddNotesInput && (
        <ModalFooter>
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1, marginTop: 0 }}
              placeholder="Enter notes here"
              name="name"
              value={value}
              onChange={onChange}
            />
            <IconButton
              color="primary"
              sx={{ p: "10px" }}
              aria-label="directions"
              onClick={onSubmit}
            >
              <AddNote />
            </IconButton>
          </Paper>
        </ModalFooter>
      )}
    </ModalContainer>
  );
};
