import {
  FETCH_FINDINGS_START,
  FETCH_FINDINGS_END,
  SET_FINDINGS,
} from "../../actionTypes";

const initialState = {
  findings: [],
  findingsLoad: false,
};

export const findingReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_FINDINGS_START:
      return {
        ...state,
        findingsLoad: true,
      };
    case FETCH_FINDINGS_END:
      return {
        ...state,
        findingsLoad: false,
      };
    case SET_FINDINGS: {
      return {
        ...state,
        findings: payload,
      };
    }
    default:
      return state;
  }
};
