export const prepareData = (data) => {
  return data.map((item) => {
    return {
      ...item,
      ...item.data,
    };
  });
};

export const filterCompanyWidthProject = (data) => {
  return data?.filter((company) => company.projects.length);
};
