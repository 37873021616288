import {
  FETCH_PROJECTS_START,
  FETCH_PROJECTS_END,
  FETCH_PROJECT_START,
  FETCH_PROJECT_END,
  SET_PROJECTS,
  SET_PROJECT,
} from "../../actionTypes";

const initialState = {
  projects: [],
  projectsLoad: false,
  curentProject: {},
};

export const projectReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_PROJECTS_START:
    case FETCH_PROJECT_START:
      return {
        ...state,
        projectsLoad: true,
      };
    case FETCH_PROJECTS_END:
    case FETCH_PROJECT_END:
      return {
        ...state,
        projectsLoad: false,
      };
    case SET_PROJECTS: {
      return {
        ...state,
        projects: payload,
      };
    }
    case SET_PROJECT: {
      return {
        ...state,
        curentProject: payload,
      };
    }
    default:
      return state;
  }
};
