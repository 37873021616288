import React from "react";
import { useNavigate } from "react-router-dom";
import { FormRenderer } from "../../components/Form/FormRender";
import { ROUTES } from "../../router";
import { AppConfig } from "../../config";

export const RegistrationPage = () => {
  const navigate = useNavigate();

  const onSubmitDone = () => {
    navigate(`${ROUTES.ROOT}`);
  };

  return (
    <div className="registration_form">
      <div className="login_header">
        <h2>Registration</h2>
      </div>
      <FormRenderer
        url={AppConfig.apiUrl + "/auth/register"}
        onSubmit={onSubmitDone}
      />
    </div>
  );
};
