import React from "react";
import { Outlet } from "react-router-dom";
import logo from "../../assets/icons/Logo.png";
import vector from "../../assets/icons/Vector.png";

import "./style.css";

export const LoginLayout = () => {
  return (
    <div className="main_container">
      <div className="wrapper">
        <header className="company_logo">
          <img src={logo} alt="logo" className="logo" />
        </header>

        <div className="content">
          <Outlet />
        </div>
        <footer className="need_help">
          <img src={vector} alt="" className="vector" />
          Need help?&nbsp;
          <a href="/">Contact support</a>
        </footer>
      </div>
    </div>
  );
};
