import React from "react";

import PropTypes from "prop-types";
import {
  ModalContainer,
  ModalContent,
  ModalHeader,
} from "../../ui-components/ModalConstructor";
import { Importer } from "../../Importer";

export const ImportModal = ({
  onClose,
  title,
  description,
  importerType,
  projectId,
  companyId,
  getData,
  topic,
  width = "500px",
  ref,
}) => {
  return (
    <ModalContainer width={width} onClose={onClose} ref={ref}>
      <ModalHeader
        onClose={onClose}
        title={title}
        description={description}
        type="IMPORT"
        topic={topic}
      />
      <ModalContent>
        <Importer
          importerType={importerType}
          projectId={projectId}
          companyId={companyId}
          onClose={onClose}
          getData={getData}
        />
      </ModalContent>
    </ModalContainer>
  );
};

ImportModal.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  isLoading: PropTypes.bool,
  projectId: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
};
ImportModal.defaultProps = {
  onClose: () => {},
  onSubmit: () => {},
  title: "",
  description: "",
  isLoading: false,
};
