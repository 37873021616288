import {
  FETCH_COMPANIES_START,
  FETCH_COMPANIES_END,
  SET_COMPANIES,
  ARCHIVE_COMPANY,
} from "../../actionTypes/index";
import api from "../../../apiSingleton";

export function getCompanies(params) {
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_COMPANIES_START,
      });
      const data = await api.companies.getCompanies(params);
      dispatch({
        type: SET_COMPANIES,
        payload: data,
      });
    } catch (error) {
      //console.log(error);
    } finally {
      dispatch({
        type: FETCH_COMPANIES_END,
      });
    }
  };
}
