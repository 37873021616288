import React from "react";
import { NotesItem } from "./NotesItem";

export const Notes = ({ notes }) => {
  return (
    <div className="notes_container">
      {notes?.map((note) => (
        <NotesItem note={note} key={note._id} />
      ))}
    </div>
  );
};
