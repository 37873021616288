import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CollapsibleTable } from "../../components/Table/DashboardTable/CollapsibleTable";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { FilterButton } from "../../components/PageFilters";
import { getDashboardData } from "../../store/actions/dashboard";
import { getCompanies } from "../../store/actions/company";
import {
  dashboardDataLoadingSelector,
  dashboardProjectsSelector,
} from "../../store/selectors/dashboard";
import { setSessionData } from "../../store/actions/session";
import { sessionSelector } from "../../store/selectors/session";
import { PageSearch } from "../../components/PageSearch";
import { useDebounce } from "../../hooks/useDebounce";

import "./style.css";

export const DashboardPage = () => {
  const buttons = [
    {
      id: 1,
      value: "All Projects",
    },
    {
      id: 2,
      value: "My Projects",
    },
    {
      id: 3,
      value: "In Preparation for Submission",
    },
    {
      id: 4,
      value: "Agency Review",
    },
    {
      id: 5,
      value: "Responding to Agency",
    },
    {
      id: 6,
      value: "Unassigned",
    },
  ];
  const dispatch = useDispatch();
  const dashboardProjects = useSelector(dashboardProjectsSelector);
  const sessionData = useSelector(sessionSelector);
  const loading = useSelector(dashboardDataLoadingSelector);

  const [filteredValue, setFilteredValue] = useState("All Projects");
  const [query, setQuery] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const debouncedValue = useDebounce(searchValue);
  const [collapsed, setcolapsed] = useState(null);

  useEffect(() => {
    if (dashboardProjects.length > 0 && collapsed === null) {
      const data = dashboardProjects.map((item) => false);
      setcolapsed(data);
    }
  }, [dashboardProjects]);

  const { name: userName } = sessionData;

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("session"));
    dispatch(setSessionData(data));
  }, []);

  useEffect(() => {
    switch (filteredValue) {
      case "In Preparation for Submission":
        return setQuery({ status: "In Preparation for Submission" });
      case "Agency Review":
        return setQuery({ status: "Agency Review" });
      case "Responding to Agency":
        return setQuery({ status: "Responding to Agency" });
      case "Unassigned":
        return setQuery({ notAssigned: true });
      case "My Projects":
        return setQuery({ myProjects: true });
      default:
        setQuery({});
    }
  }, [filteredValue]);

  const fetchData = async () => {
    const queryParams = { ...query, text: debouncedValue };
    dispatch(getDashboardData(queryParams));
  };

  useEffect(() => {
    fetchData();
    dispatch(getCompanies());
  }, [query, debouncedValue]);

  const handleSearch = (e) => {
    const value = e.target.value;

    setSearchValue(value);
  };

  const hanldeCollaps = (index) => {
    const newCollapsItem = collapsed.map((item, i) => {
      if (i === index) {
        item = !item;
      }
      return item;
    });
    setcolapsed(newCollapsItem);
  };

  const handleCollapsAll = (e) => {
    const value = e.target.innerText;
    const newCollapsItem = collapsed?.map((item) =>
      value === "Expand All" ? true : false
    );
    setcolapsed(newCollapsItem);
  };

  return (
    <div style={{ position: "relative" }}>
      <Grid
        container
        justifyContent="space-between"
        style={{
          alignItems: "center",
          paddingTop: "16px",
          paddingLeft: "24px",
          paddingRight: "24px",
        }}
      >
        <Grid item>
          <Grid container flexDirection="column">
            <Grid item>
              <Breadcrumbs>
                <Typography style={{ fontSize: "12px" }}>Dashboard</Typography>
                <Typography style={{ fontSize: "12px", color: "black" }}>
                  Projects
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item style={{ marginBottom: "16px" }}>
              <Typography style={{ fontSize: "24px", fontWeight: "600" }}>
                Welcome, {userName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Grid
        container
        style={{
          paddingLeft: "24px",
          paddingRight: "24px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid item>
          <PageSearch value={searchValue} handleSearch={handleSearch} />
        </Grid>
        <Grid item>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <FilterButton
              buttons={buttons}
              filter={filteredValue}
              changeFilter={setFilteredValue}
            />
            <div className="collapse_option" onClick={handleCollapsAll}>
              {collapsed?.some((item) => item === true) ? (
                <>
                  <RemoveCircleOutlineOutlinedIcon />
                  <p> Collapse All</p>
                </>
              ) : (
                <>
                  <AddCircleOutlineOutlinedIcon />
                  <p>Expand All</p>
                </>
              )}
            </div>
          </div>
        </Grid>
        <Grid
          item
          style={{
            marginTop: "24px",
          }}
        >
          {dashboardProjects?.map((project, index) => {
            return (
              <CollapsibleTable
                key={index}
                project={project}
                loading={loading}
                open={collapsed?.[index]}
                hanldeCollaps={hanldeCollaps}
                index={index}
              />
            );
          })}
        </Grid>
      </Grid>
    </div>
  );
};
