import { formatDate } from "./formatDate";

export function dumpData(data) {
  return data.map((item) => {
    return {
      ...item,
      modified: formatDate(item.modified),
      created: formatDate(item.created),
    };
  });
}
