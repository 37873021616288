export const ROUTES = {
  ROOT: "/",
  LOGIN: "login",
  DASHBOARD: "/dashboard",
  PROJECT_MANAGEMENT: "project-management",
  COMPANY_MANAGEMENT: "organization-management",
  AGENCY: "agency",
  USER_MANAGEMENT: "user-management",
  REGISTRATION: "registration",
  ADMIN_MANAGEMENT: "admin-management",
  DOCUMENT_MANAGEMENT: "document-management",
  ACCOUNT_CONFIRMATION: "auth/account_confirmation",
  RESET_PASSWORD: "auth/reset_password_token",
  FORGOT_PASSWORD: "forgot-password",
  FINDING_MANAGEMENT: "finding-management",
};
