import {
  FETCH_DOCUMENTS_START,
  FETCH_DOCUMENTS_END,
  SET_DOCUMENTS,
} from "../../actionTypes";

const initialState = {
  documents: [],
  documentsLoad: false,
};

export const documentReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_DOCUMENTS_START:
      return {
        ...state,
        documentsLoad: true,
      };
    case FETCH_DOCUMENTS_END:
      return {
        ...state,
        documentsLoad: false,
      };
    case SET_DOCUMENTS: {
      return {
        ...state,
        documents: payload,
      };
    }
    default:
      return state;
  }
};
