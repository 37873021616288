import React from "react";

function SearchIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        fill="#BDBDBD"
        d="M11.113 9.826a5.93 5.93 0 001.225-3.652C12.338 2.783 9.625 0 6.213 0 2.8 0 0 2.783 0 6.174c0 3.391 2.8 6.174 6.213 6.174 1.4 0 2.712-.435 3.674-1.218l2.625 2.61c.175.173.438.26.613.26a.948.948 0 00.613-.26.837.837 0 000-1.218l-2.625-2.696zm-4.9.696c-2.45 0-4.463-1.913-4.463-4.348 0-2.435 2.013-4.435 4.463-4.435s4.462 2 4.462 4.435c0 2.435-2.013 4.348-4.463 4.348z"
      ></path>
    </svg>
  );
}

export default SearchIcon;
