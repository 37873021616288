import {
  FETCH_ADMIN_USERS_START,
  FETCH_ADMIN_USERS_END,
  SET_ADMIN_USERS,
} from "../../actionTypes";
import { prepareData } from "../../helpers";

const initialState = {
  admins: [],
  adminsLoad: false,
};

export const adminReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ADMIN_USERS_START:
      return {
        ...state,
        adminsLoad: true,
      };
    case FETCH_ADMIN_USERS_END:
      return {
        ...state,
        adminsLoad: false,
      };
    case SET_ADMIN_USERS: {
      return {
        ...state,
        admins: prepareData(payload),
      };
    }
    default:
      return state;
  }
};
