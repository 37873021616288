import { Notes } from "../../../Icons";
import {
  Reminder,
  AddDocument,
  ImportIcon,
  FindingIcon,
  BudgetIcon,
  AddlinkIcon,
} from "../../../Icons";

export const renderIcon = (type) => {
  switch (type) {
    case "NOTES":
      return <Notes />;
    case "REMINDER":
      return <Reminder />;
    case "DOCUMENT":
      return <AddDocument />;
    case "IMPORT":
      return <ImportIcon />;
    case "ITEM":
      return <FindingIcon />;
    case "BUDGET":
      return <BudgetIcon />;
    case "LINK":
      return <AddlinkIcon />;
    default:
      return;
  }
};
