export const dashboardSelector = (state) => {
  return state.dashboard.dashboardData;
};

export const dashboardDataLoadingSelector = (state) => {
  return state.dashboard.dashboardDataLoading;
};

export const dashboardProjectsSelector = (state) => {
  const dashboardData = state.dashboard.dashboardData;
  return dashboardData
    .map((company) => {
      return company.projects.map((project) => {
        return {
          ...project,
          companyId: company._id,
          companyLogoURL: company?.data.logo,
        };
      });
    })
    .flat();
};
