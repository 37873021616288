import * as React from "react";

const AddlinkIcon = (props) => (
  <svg
    width={45}
    height={45}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={45} height={45} rx={12} fill="#2F80ED" fillOpacity={0.1} />
    <path
      d="M23.708 21.292a4.271 4.271 0 0 1 0 6.041l-2.417 2.416a4.271 4.271 0 1 1-6.04-6.04l2.122-2.062"
      stroke="#2F80ED"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
    <path
      d="M21.292 23.708a4.271 4.271 0 0 1 0-6.04l2.416-2.417a4.271 4.271 0 1 1 6.041 6.04l-2.122 2.063"
      stroke="#2F80ED"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);

export default AddlinkIcon;
