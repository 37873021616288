import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { LinkIcon } from "../../Icons";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { EditDocument } from "../../Icons";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import { deleteDocument } from "../../../store/actions/document";
import Box from "@mui/material/Box";
import Popper from "@material-ui/core/Popper";
import { PopperModal } from "../../Modals/PopperModal";
import { DocumentModal } from "../../Modals/DocumentModal";
import { ShowNotesModal } from "../../Modals/ShowNotesModal";
import { AddLinkModal } from "../../Modals/AddLinkModal";
import { renderTableStatus } from "../utils";
import { formatDate } from "../../../utils/formatDate";
import { createNote } from "../../../store/actions/note";
import { addLink, deleteLink } from "../../../store/actions/link";
import "../general_css/style.css";
import "./style.css";

export function DocumentGridTable({ documents, projectData }) {
  const dispatch = useDispatch();
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [documentId, setDocumentId] = useState(false);
  const [deletedDocument, setDeleteDocument] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpenNoteModal, setIsOpenNoteModal] = useState(false);
  const [isOpenLinkModal, setIsOpenLinkModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [notesData, setNotesData] = useState({});
  const [link, setLink] = useState({ url: "", linkText: "" });
  const { companyId } = projectData;

  const ListItem = styled("li")(({ theme }) => ({
    marginBottom: "15px",
    marginRight: "5px",
  }));

  const modalDescription = `${selectedDocument?.data?.name}`;

  const handleClick = (documentData, event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setDeleteDocument(documentData);
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popper" : undefined;

  const dumpData = documents?.map((document) => {
    return {
      ...document,
      id: document._id,
    };
  });

  const handleDeleteDocument = async (id) => {
    try {
      dispatch(deleteDocument(id));
    } catch (err) {
      //console.log(err);
    } finally {
      setDeleteDocument(null);
    }
  };

  const handleOpenEditModal = (id) => {
    setDocumentId(id);
    setIsOpenEditModal(true);
  };

  const handleCloseDocumentModal = () => {
    setIsOpenEditModal(false);
  };

  const handleOpenNoteModal = (data) => {
    setIsOpenNoteModal(true);
    setSelectedDocument(data);
  };

  const handleCloseNoteModal = () => {
    setIsOpenNoteModal(false);
    setSelectedDocument(null);
    setNotesData({});
  };

  const handleChangeNotes = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setNotesData({
      [name]: value,
      companyProject: {
        _id: projectData._id,
      },
      document: {
        _id: selectedDocument._id,
      },
    });
  };

  const handleSubmitNotes = async () => {
    try {
      await dispatch(createNote(notesData));
      setIsOpenNoteModal(false);
      setNotesData({});
    } catch (error) {
      console.log(error);
    }
  };
  const handleOpenLinkModal = (id) => {
    setDocumentId(id);
    setIsOpenLinkModal(true);
  };

  const handleCloseLinkModal = () => {
    setIsOpenLinkModal(false);
    setDocumentId(null);
    setLink({});
  };

  const handleChangeLink = (e) => {
    const fieldName = e.target.name;
    const value = e.target.value;
    setLink((prev) => {
      return {
        ...prev,
        [fieldName]: value,
      };
    });
  };

  const handleSubmitLink = async () => {
    const data = {
      ...link,
      companyProject: {
        _id: projectData._id,
      },
      company: {
        _id: companyId,
      },
      document: {
        _id: documentId,
      },
    };

    try {
      dispatch(addLink(data));
      setIsOpenLinkModal(false);
      setDocumentId(null);
      setLink({});
    } catch (err) {
      console.log("add link", err);
    }
  };

  const handleDeleteLink = (e, id) => {
    try {
      e.preventDefault();
      dispatch(deleteLink(id));
    } catch (error) {
      console.log(error);
    }
  };
  const projectColumns = [
    {
      // Necessary for edit/delete
      field: "_id",
      headerName: "Id",
      hide: true,
    },
    {
      field: "data.name",
      headerName: "Document Title",
      sortable: true,
      flex: 2,
      renderCell: (params) => {
        return (
          <div className="header_column">{params.row.data.name || "-"}</div>
        );
      },
    },
    {
      field: "type",
      headerName: "Document Type",
      sortable: true,
      flex: 2,
      renderCell: (params) => {
        return (
          <div className="header_column">
            {params.row.data.documentType || "-"}
          </div>
        );
      },
    },
    {
      field: "data.status",
      headerName: "Status",
      sortable: true,
      flex: 1,
      renderCell: (params) => {
        const status = params.row.data.status;
        return renderTableStatus(status, "DOCUMENT");
      },
    },
    {
      field: "assigned",
      headerName: "Assigned To",
      sortable: true,
      flex: 2,
      valueGetter: (params) => {
        return params.row.data.owner.data.name || "-";
      },
    },
    {
      field: "date",
      headerName: "Release Date",
      sortable: true,
      flex: 2,
      valueGetter: (params) => {
        return formatDate(params.row.data.releaseDate) || "-";
      },
    },
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <IconButton
              aria-label="edit"
              sx={{ mr: 1 }}
              onClick={() => handleOpenEditModal(params.row._id)}
            >
              <EditDocument />
            </IconButton>
            <IconButton
              aria-label="edit"
              sx={{ mr: 1 }}
              onClick={() => handleOpenNoteModal(params.row)}
            >
              <ArticleOutlinedIcon />
            </IconButton>
            <IconButton
              sx={{ p: "10px", color: "#EB5757" }}
              aria-label="directions"
              // onClick={() => handleDeleteDocument(params.row._id)}
              onClick={handleClick.bind(null, params.row)}
            >
              <DeleteForeverOutlinedIcon />

              <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement="left"
                disablePortal={false}
              >
                <PopperModal
                  title={`Are you sure you want to detele ${deletedDocument?.data?.name}?`}
                  onSubmit={() => handleDeleteDocument(deletedDocument?._id)}
                />
              </Popper>
            </IconButton>
          </Box>
        );
      },
    },
  ];

  return (
    <>
      {/* <DataGrid
        columns={projectColumns}
        rows={dumpData}
        style={{ height: "inherit" }}
        hideFooter
        autoHeight
      /> */}
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Document Title</TableCell>
              <TableCell align="left">Document Type</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Assigned To</TableCell>
              <TableCell align="left">Release Date</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dumpData?.map((row) => {
              const { links } = row;

              return (
                <>
                  <TableRow
                    key={row.name}
                    sx={{
                      "td,  th": { border: 0, padding: 0, paddingLeft: "16px" },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      {row?.data?.name}
                    </TableCell>
                    <TableCell align="left">
                      {row.data?.documentType || "-"}
                    </TableCell>
                    <TableCell align="left">
                      {renderTableStatus(row.data?.status, "DOCUMENT")}
                    </TableCell>
                    <TableCell align="left">
                      {row.data?.owner?.data?.name || "-"}
                    </TableCell>
                    <TableCell align="left">
                      {formatDate(row?.data?.releaseDate) || "-"}
                    </TableCell>
                    <TableCell align="left">
                      <Box
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <IconButton
                          aria-label="edit"
                          sx={{ mr: 1 }}
                          onClick={() => handleOpenEditModal(row._id)}
                        >
                          <EditDocument />
                        </IconButton>
                        <IconButton
                          aria-label="edit"
                          sx={{ mr: 1 }}
                          onClick={() => handleOpenNoteModal(row)}
                        >
                          <ArticleOutlinedIcon />
                        </IconButton>
                        <IconButton
                          sx={{ p: "10px", color: "#EB5757" }}
                          aria-label="directions"
                          // onClick={() => handleDeleteDocument(params.row._id)}
                          onClick={handleClick.bind(null, row)}
                        >
                          <DeleteForeverOutlinedIcon />

                          <Popper
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            placement="left"
                            disablePortal={false}
                          >
                            <PopperModal
                              title={`Are you sure you want to detele ${deletedDocument?.data?.name}?`}
                              onSubmit={() =>
                                handleDeleteDocument(deletedDocument?._id)
                              }
                            />
                          </Popper>
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    style={{
                      borderBottom: "1px solid #E0E0E0",
                      width: "100%",
                    }}
                  >
                    <TableCell colSpan={6}>
                      <div
                        style={{
                          paddingLeft: "16px",
                          width: "100%",
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div>
                          <Stack
                            direction="row"
                            alignItems="center"
                            gap={1}
                            style={{ marginRight: "10px", height: "32px" }}
                          >
                            <LinkIcon />
                            <Typography
                              variant="body1"
                              style={{
                                color: "#333333",
                                fontWeight: 700,
                                fontSize: "14px",
                              }}
                            >
                              Links:
                            </Typography>
                          </Stack>
                        </div>
                        <Paper
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            listStyle: "none",
                            background: "inherit",
                            boxShadow: "none",
                            justifyContent: "start",
                            padding: "0 5px",
                            m: 0,
                          }}
                          component="ul"
                        >
                          {links.map((link) => {
                            return (
                              <ListItem key={link._id}>
                                <Chip
                                  label={link.data?.linkText}
                                  component="a"
                                  href={link.data?.url}
                                  clickable
                                  target="_blank"
                                  style={{
                                    background: "rgba(47, 128, 237, 0.09)",
                                    color: "#2F80ED",
                                  }}
                                  onDelete={(e) =>
                                    handleDeleteLink(e, link._id)
                                  }
                                />
                              </ListItem>
                            );
                          })}
                        </Paper>
                        <div style={{ width: "140px" }}>
                          <Button
                            size="small"
                            startIcon={<AddCircleIcon />}
                            onClick={() => handleOpenLinkModal(row._id)}
                          >
                            Add link
                          </Button>
                        </div>
                      </div>
                    </TableCell>
                  </TableRow>
                </>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {isOpenEditModal && (
        <DocumentModal
          title="Edit Document"
          description="Update document details here"
          mode="EDIT"
          documentId={documentId}
          onClose={handleCloseDocumentModal}
        />
      )}
      {isOpenNoteModal && (
        <ShowNotesModal
          onClose={handleCloseNoteModal}
          title="Notes"
          topic="Document"
          description={modalDescription}
          notes={selectedDocument?.notes}
          value={notesData.name}
          onChange={handleChangeNotes}
          onSubmit={handleSubmitNotes}
          showAddNotesInput={true}
        />
      )}
      {isOpenLinkModal && (
        <AddLinkModal
          title="Add Link"
          description="Add a URL in this document"
          value={link}
          onClose={handleCloseLinkModal}
          onChange={handleChangeLink}
          onSubmit={handleSubmitLink}
        />
      )}
    </>
  );
}
