import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { TabLayout } from "../../components/TabLayout";
import { DocumentPage } from "./document_management";
import { Importer } from "../../components/Importer";
import { ImporterType } from "../../constants/importer";

export const DocumentTabLayoutPage = () => {
  return (
    <div>
      <Grid
        container
        justifyContent="space-between"
        style={{
          alignItems: "center",
          paddingTop: "16px",
          paddingLeft: "24px",
          paddingRight: "24px",
        }}
      >
        <Grid item xs={9} style={{ marginBottom: "16px" }}>
          <Grid container flexDirection="column">
            <Grid item>
              <Breadcrumbs>
                <Typography style={{ fontSize: "12px" }}>Dashboard</Typography>
                <Typography style={{ fontSize: "12px", color: "black" }}>
                  Document Management
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item style={{ marginBottom: "24px" }}>
              <Typography style={{ fontSize: "24px", fontWeight: "600" }}>
                Document Management
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0px 0px",
        }}
      >
        <TabLayout>
          <DocumentPage pageTitle={"Documents"} />
          <Importer
            pageTitle={"Documents Importer"}
            importerType={ImporterType.PROJECT_DOCUMENTS}
          />
        </TabLayout>
      </Grid>
    </div>
  );
};
