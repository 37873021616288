import {
  FETCH_DASHBOARD_DATA_START,
  FETCH_DASHBOARD_DATA_END,
  SET_DASHBOARD_DATA,
} from "../../actionTypes";
import { filterCompanyWidthProject } from "../../helpers";

const initialState = {
  dashboardData: [],
  dashboardDataLoading: false,
};

export const dashboardReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_DASHBOARD_DATA_START:
      return {
        ...state,
        dashboardDataLoading: true,
      };
    case FETCH_DASHBOARD_DATA_END:
      return {
        ...state,
        dashboardDataLoading: false,
      };
    case SET_DASHBOARD_DATA: {
      return {
        ...state,
        dashboardData: filterCompanyWidthProject(payload),
      };
    }
    default:
      return state;
  }
};
