import React, {useEffect} from "react";
import { AgencyLayout } from "../../layout/AgencyLayout";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {ROUTES} from "../../router";
import {useNavigate} from "react-router-dom";
import {Auth} from "../../modules/auth";

export const AgencyPage = () => {
    const navigate = useNavigate();
    const auth = Auth();

    useEffect(() => {
        if (auth.getUser() === null || auth.getUser().superAdmin !== true) {
            navigate(`${ROUTES.DASHBOARD}`);
        }
    })

    return (
    <div>
      <Grid
        container
        justifyContent="space-between"
        style={{
          alignItems: "center",
          paddingTop: "16px",
          paddingLeft: "24px",
          paddingRight: "24px",
        }}
      >
        <Grid item xs={9} style={{ marginBottom: "16px" }}>
          <Grid container flexDirection="column">
            <Grid item>
              <Breadcrumbs>
                <Typography style={{ fontSize: "12px" }}>Dashboard</Typography>
                <Typography style={{ fontSize: "12px", color: "black" }}>
                  Agency Management
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item style={{ marginBottom: "24px" }}>
              <Typography style={{ fontSize: "24px", fontWeight: "600" }}>
                Agency Management
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0px 0px",
        }}
      >
        <AgencyLayout />
      </Grid>
    </div>
  );
};
