import React from "react";

const AgencyIcon = (props) => (
  <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.484 0H.645A.639.639 0 0 0 0 .633v16.446c0 .349.288.632.645.632h10.323v-4.653a5.597 5.597 0 0 1-1.29-3.57c0-3.138 2.604-5.693 5.806-5.693.218 0 .433.014.645.038v-3.2A.639.639 0 0 0 15.484 0ZM7.097 12.651H2.58v-1.265h4.516v1.265Zm0-3.163H2.58V8.223h4.516v1.265Zm1.29-3.162H2.581V5.06h5.806v1.266Z"
      fill={props.color}
    />
    <path
      d="M15.484 15.181a5.854 5.854 0 0 1-3.226-.963V20l3.226-1.582L18.71 20v-5.782a5.854 5.854 0 0 1-3.226.963ZM15.484 13.916c2.494 0 4.516-1.982 4.516-4.428 0-2.445-2.022-4.427-4.516-4.427s-4.516 1.982-4.516 4.427c0 2.446 2.022 4.428 4.516 4.428Z"
      fill={props.color}
    />
  </svg>
);

export default AgencyIcon;
