import React from "react";

const ProjectMenagementIcon = (props) => (
  <svg width={20} height={18} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.333 2.455V0h-2.5A.826.826 0 0 0 0 .818v1.637h3.333ZM0 4.09v13.092c0 .452.373.818.833.818h18.334c.46 0 .833-.366.833-.818V4.09H0Zm9.167 9.819H2.5V7.364h6.667v6.545ZM15 12.273h-4.167v-1.637H15v1.637ZM17.5 9h-6.667V7.364H17.5V9ZM20 2.455V.818A.826.826 0 0 0 19.167 0H5v2.455h15Z"
      fill={props.color}
    />
  </svg>
);

export default ProjectMenagementIcon;
