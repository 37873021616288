import React, { useEffect, useState } from "react";
import "formiojs/dist/formio.builder.min.css";
import { SimpleDisplayGrid } from "../../SimpleDisplayGrid";
import Box from "@mui/material/Box";
import { AppConfig } from "../../../config";

const columns = [
  {
    // Necessary for edit/delete
    field: "_id",
    headerName: "Id",
    hide: true,
    valueGetter: (params) => {
      return params.row._id;
    },
  },
  {
    field: "data.name",
    headerName: "Document Name",
    sortable: true,
    flex: 1,
    valueGetter: (params) => {
      return params.row.data.name;
    },
  },

  {
    field: "data.agencyTemplate.data.name",
    headerName: "Agency Template Name",
    sortable: true,
    flex: 1,
    valueGetter: (params) => {
      return params.row.data.agencyTemplate.data.name;
    },
  },
  {
    field: "data.agency.data.name",
    headerName: "Agency Name",
    sortable: true,
    flex: 1,

    valueGetter: (params) => {
      return params.row.data.agencyTemplate.data.agency.data.name;
    },
  },
  {
    field: "created",
    headerName: "Created on",
    sortable: true,
    flex: 1,
  },
  {
    field: "modified",
    headerName: "Modified on",
    sortable: true,
    flex: 1,
  },
];

const style = { marginTop: "24px" };
export const DocumentTemplateTable = ({
  pageTitle,
  addButtonTitle,
  children,
}) => {
  return (
    <Box sx={{ ml: "24px",  mr: "24px" }}>
      <SimpleDisplayGrid
        url={AppConfig.apiUrl + "/document_template/available"}
        addFormUrl={AppConfig.apiUrl + "/document_template"}
        getEditFormUrl={AppConfig.apiUrl + "/document_template"}
        editFormUrl={AppConfig.apiUrl + "/document_template"}
        deleteFormUrl={AppConfig.apiUrl + "/document_template"}
        columns={columns}
        buttonTitle={addButtonTitle}
        children={children}
        agencyStyle={style}
      />
    </Box>
  );
};
