import { Auth } from "../modules/auth";

export const checkPermissions = (role) => {
  if (!role) return;
  const auth = Auth();
  const user = auth.getUser();
  const { roles } = user;

  return roles.includes(role);
};
