import Base from "./Base";

export default class ProjectsApi extends Base {
  getProjects(params) {
    return this.apiClient.get("project/available", params);
  }
  getProject(id) {
    return this.apiClient.get(`project/submission/${id}`);
  }
  submitProject(id) {
    return this.apiClient.post(`project/submission/send_to_agency/${id}`);
  }
}
