import React from "react";

const CompanyMenagementIcon = (props) => (
  <svg width={20} height={20} xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.75 11.25h-2.5v-7.5c0-.75-.5-1.25-1.25-1.25h-3.75V0h-2.5v2.5H5c-.75 0-1.25.5-1.25 1.25v7.5h-2.5c-.75 0-1.25.5-1.25 1.25v6.25C0 19.5.5 20 1.25 20h17.5c.75 0 1.25-.5 1.25-1.25V12.5c0-.75-.5-1.25-1.25-1.25ZM6.25 12.5V5h7.5v12.5h-2.5v-3.75h-2.5v3.75h-2.5v-5Z"
      fill={props.color}
    />
    <path d="M11.25 7.5h-2.5V10h2.5V7.5Z" fill={props.color} />
  </svg>
);

export default CompanyMenagementIcon;
