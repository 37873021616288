import React from "react";
import PropTypes from "prop-types";
import {
  ModalContainer,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "../../ui-components/ModalConstructor";
import { TextField } from "@mui/material";

export const AddNotesModal = ({
  onClose,
  onSubmit,
  title,
  description,
  topic,
  value,
  onChange,
  width = "500px",
  isLoading = false,
  cancelButtonProps = {},
  submitButtonProps = {},
  ref,
}) => {
  return (
    <ModalContainer width={width} onClose={onClose} ref={ref}>
      <ModalHeader
        onClose={onClose}
        title={title}
        description={description}
        type="NOTES"
        topic={topic}
      />
      <ModalContent>
        <div
          style={{
            width: "100%",
            minHeight: "130px",
            height: "fit-content",
            border: "1px solid black",
            padding: "8px 10px",
          }}
        >
          <TextField
            value={value}
            onChange={onChange}
            name="name"
            sx={{ border: "none" }}
            multiline
            variant="standard"
            placeholder="Enter notes here"
            style={{}}
            className="notes"
          />
        </div>
      </ModalContent>
      <ModalFooter
        buttons={[
          {
            label: "Cancel",
            isLoading: false,
            visible: true,
            onClick: onClose,
            btnStyle: "white",
            ...cancelButtonProps,
          },
          {
            label: "Update Notes",
            isLoading,
            visible: true,
            onClick: onSubmit,
            btnStyle: "black",
            ...submitButtonProps,
          },
        ]}
      />
    </ModalContainer>
  );
};

AddNotesModal.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  isLoading: PropTypes.bool,
};
AddNotesModal.defaultProps = {
  onClose: () => {},
  onSubmit: () => {},
  title: "",
  description: "",
  isLoading: false,
};
