import {
  FETCH_DASHBOARD_DATA_START,
  FETCH_DASHBOARD_DATA_END,
  SET_DASHBOARD_DATA,
} from "../../actionTypes/index";
import api from "../../../apiSingleton";

export function getDashboardData(params = { text: "" }) {
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_DASHBOARD_DATA_START,
      });

      const data = await api.dashboard.getDashboardData(params);

      dispatch({
        type: SET_DASHBOARD_DATA,
        payload: data.companies,
      });
    } catch (error) {
      //console.log(error);
    } finally {
      dispatch({
        type: FETCH_DASHBOARD_DATA_END,
      });
    }
  };
}
