import React, { useEffect } from "react";
import BudgetGridTable from "../../Table/BudgetTable";
import { useDispatch, useSelector } from "react-redux";
import {
  ModalContainer,
  ModalContent,
  ModalHeader,
} from "../../ui-components/ModalConstructor";
import {
  getExpenses,
  clearCurentExpenses,
} from "../../../store/actions/expenses";
import { expensesSelector } from "../../../store/selectors/expenses";

export const BudgetModal = ({
  onClose,
  title,
  description,
  topic,
  width = "1120px",
  isEditBudget,
  project,
  projectStatus,
  ref,
}) => {
  const dispatch = useDispatch();
  const { _id: projectId } = project;

  const budget = useSelector(expensesSelector);

  useEffect(() => {
    dispatch(getExpenses(projectId));
  }, [projectId]);

  const handleClose = () => {
    dispatch(clearCurentExpenses());
    onClose();
  };

  return (
    <ModalContainer width={width} onClose={handleClose} ref={ref}>
      <ModalHeader
        onClose={handleClose}
        title={title}
        description={description}
        type="BUDGET"
        topic={topic}
      />

      <ModalContent>
        <BudgetGridTable
          budget={budget}
          projectStatus={projectStatus}
          isEditBudget={isEditBudget}
        />
      </ModalContent>
    </ModalContainer>
  );
};
