import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import Avatar from "@mui/material/Avatar";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import PendingOutlinedIcon from "@mui/icons-material/PendingOutlined";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import SendIcon from "@mui/icons-material/Send";
import DollarSign from "../../Icons/Budget";
import { companiesSelector } from "../../../store/selectors/company";
import { DocumentGridTable } from "./DocumentGridTable";
import { FindingGridTable } from "./FindingGridTable";
import { ReminderInfo } from "./ReminderInfo";
import { AddNotesModal } from "../../Modals/AddNotesModal";
import { BudgetModal } from "../../Modals/BudgetModal";
import { ShowNotesModal } from "../../Modals/ShowNotesModal";
import { ReminderModal } from "../../Modals/Reminder";
import { DocumentModal } from "../../Modals/DocumentModal";
import { FindingModal } from "../../Modals/FindingModal";
import { ImportModal } from "../../Modals/ImportModal";
import { createNote } from "../../../store/actions/note";
import {
  submitProject,
  exportProject,
  exportExpense,
} from "../../../store/actions/project";
import { renderTotalRow } from "./utils";
import { ImporterType } from "../../../constants/importer";
import { getDashboardData } from "../../../store/actions/dashboard";
import { Auth } from "../../../modules/auth";
import logo from "../../../assets/icons/default_logo.svg";
import "./style.css";

function Row({ project, hanldeCollaps, index, open }) {
  const auth = Auth();
  const [isopenModal, setIsOpenModal] = useState(false);
  const [isOpenShowNotes, setIsOpenShowNotes] = useState(false);
  const [isOpenReminderModal, setIsOpenReminderModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState("");
  const [isOpenDocumentModal, setIsOpenDocumentModal] = useState(false);
  const [isOpenFindingModal, setIsOpenFindingModal] = useState(false);
  const [isopenImportModal, setIsOpenImportModal] = useState(false);
  const [isopenResponseModal, setIsOpenResponseModal] = useState(false);
  const [isOpenBudgetModal, setIsOpenBudgetModal] = useState(false);
  const companies = useSelector(companiesSelector);
  const { companyId, companyLogoURL } = project;
  const { name: projectName, status, owner } = project.data;
  const currentUser = auth.getUser();
  const productType = project?.data?.productType?.data?.name;
  const [notesData, setNotesData] = useState({});
  const dispatch = useDispatch();

  const getCompanyName = (companyId) => {
    const company = companies.find((company) => company._id === companyId);
    return company?.data?.name;
  };

  const getDashboard = () => {
    dispatch(getDashboardData());
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = (index) => {
    hanldeCollaps(index);
  };

  const handleOpenModal = (data) => {
    setIsOpenModal(true);
    setSelectedProject(data);
  };

  const handleOpenDocumentModal = (project) => {
    setSelectedProject(project);
    setIsOpenDocumentModal(true);
  };
  const handleOpenFindingModal = (project) => {
    setSelectedProject(project);
    setIsOpenFindingModal(true);
  };

  const handleOpenSHowNotesModal = (data) => {
    setIsOpenShowNotes(true);
    setSelectedProject(data);
  };

  const handleOpenReminderModal = (data) => {
    setSelectedProject(data);
    setIsOpenReminderModal(true);
  };

  const handleOpenImportModal = (data) => {
    setSelectedProject(data);
    setIsOpenImportModal(true);
  };

  const handleCloseImportModal = async () => {
    setSelectedProject(null);
    setIsOpenImportModal(false);
  };

  const handleOpenResponseModal = (data) => {
    setSelectedProject(data);
    setIsOpenResponseModal(true);
  };

  const handleCloseResponseModal = async () => {
    setSelectedProject(null);
    setIsOpenResponseModal(false);
  };

  const handleCloseReminderModal = () => {
    setIsOpenReminderModal(false);
  };

  const handleCloseModal = () => {
    setIsOpenModal(false);
    setSelectedProject(null);
    setNotesData({});
  };

  const handleCloseDocumentModal = () => {
    setIsOpenDocumentModal(false);
    setSelectedProject(null);
  };

  const handleCloseBudgetModal = () => {
    setIsOpenBudgetModal(false);
    setSelectedProject(null);
  };

  const handleCloseFindingModal = () => {
    setIsOpenFindingModal(false);
    setSelectedProject(null);
  };

  const handleCloseShowModal = () => {
    setIsOpenShowNotes(false);
  };

  const handleOpenShowBudgetModal = (data) => {
    setIsOpenBudgetModal(true);
    setSelectedProject(data);
  };

  const handelSubmitProject = async (id) => {
    try {
      await dispatch(submitProject(id));
    } catch (err) {
      console.log(err);
    }
  };

  const handelExportProject = async (project) => {
    try {
      await dispatch(exportProject(project));
    } catch (err) {
      console.log(err);
    }
  };

  const handelExportExpense = async (project) => {
    try {
      await dispatch(exportExpense(project));
    } catch (err) {
      //console.log(err);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setNotesData({
      [name]: value,
      companyProject: {
        _id: selectedProject._id,
      },
    });
  };

  const handleSubmitNotes = async () => {
    try {
      await dispatch(createNote(notesData));
      setIsOpenModal(false);
      setIsOpenShowNotes(false);
      setNotesData({});
    } catch (error) {
      console.log(error);
    }
  };

  const renderProjectTable = (projectData) => {
    if (!projectData) return;
    const {
      data: { status },
      documents,
      findings,
      reminders,
    } = projectData;

    if (status === "In Preparation for Submission") {
      return (
        <DocumentGridTable documents={documents} projectData={projectData} />
      );
    }
    if (status === "Responding to Agency") {
      return <FindingGridTable findings={findings} projectData={projectData} />;
    }
    if (status === "Agency Review") {
      return <ReminderInfo reminders={reminders} />;
    }
  };

  const modalDescription = `${selectedProject?.data?.name}`;
  const companyName = getCompanyName(companyId);

  return (
    <>
      <TableRow
        sx={{ "& > *": { borderBottom: "unset" } }}
        style={{
          backgroundColor: "#F6F7F9",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <TableCell align="left" className="collapsible_columns">
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className="header_logo">
              <Avatar src={companyLogoURL || logo} />
            </div>
            <div>
              <span className="header_title">Organization</span>
              <p className="header_value"> {companyName}</p>
            </div>
          </div>
        </TableCell>
        <TableCell align="left" className="collapsible_columns">
          <div>
            <span className="header_title">Project</span>
            <p className="header_value"> {projectName}</p>
          </div>
        </TableCell>
        <TableCell align="left" className="collapsible_columns">
          <div>
            <span className="header_title">Product Type</span>
            <p className="header_value"> {productType}</p>
          </div>
        </TableCell>
        <TableCell align="left" className="collapsible_columns">
          <div>
            <span className="header_title">Owner</span>
            <p className="header_value"> {owner.data.name}</p>
          </div>
        </TableCell>
        <TableCell align="left" className="collapsible_columns">
          <div>
            <span className="header_title">Status</span>
            <p className="header_value"> {status}</p>
          </div>
        </TableCell>
        <TableCell style={{ width: "130px" }}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            {status !== "Responding to Agency" && (
              <IconButton onClick={() => handleOpenSHowNotesModal(project)}>
                <ArticleOutlinedIcon />
              </IconButton>
            )}

            <IconButton onClick={() => handleOpenShowBudgetModal(status)}>
              <DollarSign />
            </IconButton>
            <IconButton
              onClick={handleClick}
              size="small"
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <PendingOutlinedIcon />
            </IconButton>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => handleOpen(index)}
            >
              {open ? (
                <RemoveCircleOutlineOutlinedIcon />
              ) : (
                <AddCircleOutlineOutlinedIcon />
              )}
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={openMenu}
              onClose={handleClose}
              onClick={handleClose}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              {status === "In Preparation for Submission" && (
                <MenuItem
                  className="dashboard_options"
                  onClick={handleOpenDocumentModal.bind(null, project)}
                >
                  <PostAddOutlinedIcon />
                  Add Document
                </MenuItem>
              )}
              {status === "Responding to Agency" && (
                <MenuItem
                  className="dashboard_options"
                  onClick={handleOpenFindingModal.bind(null, project)}
                >
                  <PostAddOutlinedIcon />
                  Add Item
                </MenuItem>
              )}

              {currentUser._id === owner._id && (
                <MenuItem
                  className="dashboard_options"
                  onClick={() => {
                    handleOpenReminderModal(project);
                  }}
                >
                  <NotificationsNoneOutlinedIcon />
                  Add Reminder
                </MenuItem>
              )}
              {currentUser._id === owner._id && (
                <MenuItem
                  onClick={() => handleOpenModal(project)}
                  className="dashboard_options"
                >
                  <ArticleOutlinedIcon />
                  Add Notes
                </MenuItem>
              )}
              {status !== "In Preparation for Submission" && (
                <MenuItem
                  onClick={() => handleOpenResponseModal()}
                  className="dashboard_options"
                >
                  <EmailOutlinedIcon />
                  Response received
                </MenuItem>
              )}
              {status === "In Preparation for Submission" && (
                <MenuItem
                  className="dashboard_options"
                  onClick={() => handleOpenImportModal(project)}
                >
                  <FileDownloadOutlinedIcon />
                  Import Documents
                </MenuItem>
              )}
              {status !== "Agency Review" && (
                <MenuItem
                  className="dashboard_options"
                  onClick={() => handelExportProject(project)}
                >
                  <FileUploadOutlinedIcon />
                  Export as Excel Sheet
                </MenuItem>
              )}
              {status !== "Agency Review" && (
                <MenuItem
                  className="dashboard_options"
                  onClick={() => handelExportExpense(project)}
                >
                  <FileUploadOutlinedIcon />
                  Export Expenses as Excel Sheet
                </MenuItem>
              )}

              {status === "In Preparation for Submission" && (
                <MenuItem
                  className="dashboard_options"
                  onClick={() => handelSubmitProject(project._id)}
                >
                  <SendIcon />
                  Submit
                </MenuItem>
              )}
            </Menu>
          </div>
        </TableCell>
      </TableRow>
      {project.notes.length > 0 && (
        <TableRow
          style={{
            backgroundColor: "#F6F7F9",
            alignItems: "center",
            height: "50px",
            lineHeight: "50px",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ArticleOutlinedIcon
              style={{
                marginRight: "14px",
              }}
            />
            <p
              style={{
                fontWeight: "500",
                fontSize: "14px",
                color: "#333333",
                marginBottom: "0",
              }}
            >
              {project.notes[0].data.name}
            </p>
          </Box>
        </TableRow>
      )}
      <TableRow
        style={{
          backgroundColor: "#FFFFFF",
          height: "50px",
          lineHeight: "50px",
          textAlign: "center",
        }}
      >
        {renderTotalRow(project)}
      </TableRow>
      <TableRow
        style={{
          height: "inherit",
        }}
      >
        <TableCell
          style={{
            padding: "0px",
            border: "none",
          }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto">
            <Box>
              <Table>
                <TableBody className="collapsible_table">
                  {renderProjectTable(project)}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {isopenModal && (
        <AddNotesModal
          onClose={handleCloseModal}
          onSubmit={handleSubmitNotes}
          title="Notes"
          topic="Project: "
          description={modalDescription}
          value={notesData.value}
          onChange={handleChange}
        />
      )}
      {isOpenBudgetModal && (
        <BudgetModal
          onClose={handleCloseBudgetModal}
          title="Project Expenses"
          description="View/update project expenses"
          onChange={handleChange}
          companyName={companyName}
          project={project}
          projectStatus={status}
          isEditBudget={currentUser._id === owner._id}
        />
      )}
      {isOpenShowNotes && (
        <ShowNotesModal
          onClose={handleCloseShowModal}
          title="Notes"
          topic="Project"
          description={modalDescription}
          notes={selectedProject?.notes}
          value={notesData.name}
          onChange={handleChange}
          onSubmit={handleSubmitNotes}
          showAddNotesInput={currentUser._id === owner._id}
        />
      )}
      {isOpenReminderModal && (
        <ReminderModal
          onClose={handleCloseReminderModal}
          title="Add a Reminder"
          description="Set a reminder for this project"
          data={selectedProject}
        />
      )}
      {isOpenDocumentModal && (
        <DocumentModal
          title="Add a Document"
          description="Add a new document here"
          companyId={project.companyId}
          projectId={project._id}
          mode="CREATE"
          onClose={handleCloseDocumentModal}
        />
      )}
      {isOpenFindingModal && (
        <FindingModal
          title="Add an Item"
          description="Add a new item here"
          mode="CREATE"
          onClose={handleCloseFindingModal}
          companyId={project.companyId}
          projectId={project._id}
        />
      )}
      {isopenImportModal && (
        <ImportModal
          importerType={ImporterType.PROJECT_DOCUMENTS}
          onClose={handleCloseImportModal}
          title="Import Documents"
          description="Add a new document here"
          companyId={project.companyId}
          projectId={project._id}
          getData={getDashboard}
        />
      )}
      {isopenResponseModal && (
        <ImportModal
          importerType={ImporterType.DOCUMENT_FINDINGS}
          onClose={handleCloseResponseModal}
          title="Audit Text Import"
          description="Import audit document"
          companyId={project.companyId}
          projectId={project._id}
          getData={getDashboard}
        />
      )}
    </>
  );
}

export function CollapsibleTable({ project, hanldeCollaps, index, open }) {
  return (
    <TableContainer component={Paper} style={{ marginBottom: "15px" }}>
      <Table aria-label="collapsible table">
        <TableHead>
          <Row
            project={project}
            hanldeCollaps={hanldeCollaps}
            index={index}
            open={open}
          />
        </TableHead>
      </Table>
    </TableContainer>
  );
}
