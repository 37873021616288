import * as React from "react";

const Notes = (props) => (
  <svg
    width={45}
    height={46}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      y={0.048}
      width={45}
      height={45.143}
      rx={12}
      fill="#2F80ED"
      fillOpacity={0.1}
    />
    <path
      d="M32.5 13.62h-20v18h20v-18ZM17.045 18.62h10.91M17.045 22.62h10.91M17.045 26.62h4.546"
      stroke="#2F80ED"
      strokeWidth={2}
      strokeMiterlimit={10}
      strokeLinecap="round"
    />
  </svg>
);

export default Notes;
