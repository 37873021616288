import { SET_SESSION_DATA } from "../../actionTypes/session";
const initialState = {
  sessionData: {},
};

export const sessionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_SESSION_DATA:
      return {
        ...state,
        sessionData: payload,
      };
    default:
      return state;
  }
};
