import { combineReducers } from "redux";
import { sessionReducer as session } from "./session";
import { userReducer as user } from "./user";
import { companyReducer as company } from "./company";
import { adminReducer as admin } from "./admin";
import { projectReducer as project } from "./project";
import { documentReducer as document } from "./document";
import { dashboardReducer as dashboard } from "./dashboard";
import { findingReducer as finding } from "./finding";
import { expensesReducer as expenses } from "./expenses";

export const rootReducer = combineReducers({
  session,
  user,
  company,
  admin,
  project,
  document,
  dashboard,
  finding,
  expenses,
});
