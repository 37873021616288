import * as React from "react";

const Export = (props) => (
  <svg
    width={12}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.42.067A.25.25 0 0 0 8 .25V2c-1.449.052-5 .6-5 4.746a.25.25 0 0 0 .183.24A.257.257 0 0 0 3.25 7a.248.248 0 0 0 .213-.12C4.2 5.676 5.725 5.045 8 5v1.75a.25.25 0 0 0 .42.183l3.5-3.25a.249.249 0 0 0 0-.366L8.42.067Z"
      fill="#F7F7F7"
    />
    <path
      d="M11 7a1 1 0 0 0-1 1v2H2V2h2a1 1 0 0 0 0-2H2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V8a1 1 0 0 0-1-1Z"
      fill="#F7F7F7"
    />
  </svg>
);

export default Export;
