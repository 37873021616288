import {
  FETCH_ADMIN_USERS_START,
  FETCH_ADMIN_USERS_END,
  SET_ADMIN_USERS,
  DELETE_ADMIN_USER,
} from "../../actionTypes/index";
import api from "../../../apiSingleton";

export function getAdminUsers(params = {}) {
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_ADMIN_USERS_START,
      });

      const data = await api.admins.getAdminUsers(params);

      dispatch({
        type: SET_ADMIN_USERS,
        payload: data,
      });
    } catch (error) {
      //console.log(error);
    } finally {
      dispatch({
        type: FETCH_ADMIN_USERS_END,
      });
    }
  };
}
