import classNames from "classnames";

export const renderTotalRow = (projectData) => {
  if (!projectData) {
    return;
  }
  const findingsStats = projectData.findingsStats;
  const documentsStats = projectData.documentsStats;
  let stats;

  const mapStats = {
    totalFindings: "Total Items:",
    totalDocuments: "Total Documents:",
    inPreparation: "In Preparation for Submission",
    submitted: "Submitted",
    ready: "Ready",
    closed: "Closed",
  };
  if (projectData.data.status === "Responding to Agency") {
    stats = findingsStats;
  } else {
    stats = documentsStats;
  }

  const rendeStats = (key, value) => {
    return (
      <div
        style={{
          marginRight: "25px",
          display: "flex",
          flexDirection: "row",
          height: "25px",
          lineHeight: "25px",
          alignItems: "center",
        }}
      >
        <div
          className={classNames({
            dashboard_stats: true,
            dashboard_stats_total: ["totalFindings", "totalDocuments"].some(
              (i) => i === key
            ),
            dashboard_stats_ready: key === "ready",
            dashboard_stats_closed: key === "closed",
            dashboard_stats_submitted: key === "submitted",
            dashboard_stats_inPreparation: key === "inPreparation",
          })}
        ></div>
        <span
          style={{ marginRight: "5px", marginLeft: "5px", fontSize: "14px" }}
          className={classNames({
            totalStats: ["totalFindings", "totalDocuments"].some(
              (i) => i === key
            ),
          })}
        >
          {mapStats[key]}
        </span>
        <div
          style={{ fontSize: "14px", fontWeight: "600" }}
          className={classNames({
            totalStatsValue: !["totalFindings", "totalDocuments"].some(
              (i) => i === key
            ),
          })}
        >
          {value}
        </div>
      </div>
    );
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        height: " 50px",
        alignItems: "center",
        paddingLeft: "24px",
      }}
    >
      {Object.entries(stats).map(([key, value]) => rendeStats(key, value))}
    </div>
  );
};
