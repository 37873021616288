import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { AdminTable } from "../../components/Table/AdminTable";
import Divider from "@mui/material/Divider";
import { FilterButton } from "../../components/PageFilters";
import { adminSelector } from "../../store/selectors/admin";
import { getAdminUsers } from "../../store/actions/admin";
import { Auth } from "../../modules/auth";
import { PageSearch } from "../../components/PageSearch";
import { ROUTES } from "../../router";
import { useNavigate } from "react-router-dom";
import { useDebounce } from "../../hooks/useDebounce";
import { FormRenderer } from "../../components/Form/FormRender";
import { useConstructor } from "../../components/SimpleDisplayGrid";
import { useSearchParams } from "react-router-dom";
import { AppConfig } from "../../config";
import axios from "axios";
import { errorResponseProcessor } from "../../utils/responseProcessor";

export const PasswordResetPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const auth = Auth();
  const navigate = useNavigate();

  useConstructor(() => {
    // //console.log(`userId: ${searchParams.get("userId")}`)
    // //console.log(`token: ${searchParams.get("token")}`)
  });

  const onSubmitDone = (submission, form) => {
    form.current.checkData()

    axios
      .post(
        AppConfig.apiUrl +
          "/auth/reset_password_token/" +
          `${searchParams.get("userId")}/${searchParams.get("token")}`,
        submission,
        {
          headers: auth.getAuthHeaders(),
        }
      )
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          alert(
            "New password successfully set. Page will automatically navigate to login page."
          );
          navigate(`../../`, { replace: true });
        }
      })
      .catch((error) => {
        errorResponseProcessor(error, form)
      });
  };

  return (
    <div>
      <Grid
        container
        justifyContent="space-between"
        style={{
          alignItems: "center",
          paddingTop: "16px",
          paddingLeft: "24px",
          paddingRight: "24px",
        }}
      >
        <Grid item xs={12}>
          <Grid container flexDirection="column">
            {/*<Grid item>*/}
            {/*    <Breadcrumbs>*/}
            {/*        <Typography style={{ fontSize: "12px" }}>Dashboard</Typography>*/}
            {/*        <Typography style={{ fontSize: "12px", color: "black" }}>*/}
            {/*            Password Management*/}
            {/*        </Typography>*/}
            {/*    </Breadcrumbs>*/}
            {/*</Grid>*/}
            <Grid item style={{ marginBottom: "16px", textAlign: "center" }}>
              <Typography style={{ fontSize: "24px", fontWeight: "600" }}>
                Set New Password
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Grid
        container
        alignItems={"center"}
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0px 24px",
        }}
      >
        <Grid item style={{ marginTop: "50px" }} xs={6}>
          <FormRenderer
            url={AppConfig.apiUrl + "/auth/set_new_password"}
            onSubmit={onSubmitDone}
          />
        </Grid>
      </Grid>
    </div>
  );
};
