import {
  FETCH_DOCUMENTS_START,
  FETCH_DOCUMENTS_END,
  SET_DOCUMENTS,
  DELETE_DOCUMENT,
} from "../../actionTypes/index";
import api from "../../../apiSingleton";
import { getDashboardData } from "../dashboard";

export function getDocuments(params) {
  return async (dispatch) => {
    try {
      dispatch({
        type: FETCH_DOCUMENTS_START,
      });

      const data = await api.documents.getDocuments(params);

      dispatch({
        type: SET_DOCUMENTS,
        payload: data,
      });
    } catch (error) {
      //console.log(error);
    } finally {
      dispatch({
        type: FETCH_DOCUMENTS_END,
      });
    }
  };
}

export function deleteDocument(id) {
  return async (dispatch) => {
    try {
      await api.documents.deleteDocument(id);
      await dispatch(getDashboardData());
    } catch (error) {
      //console.log("Delete document", error);
    }
  };
}

export function createDocument(data) {
  return async (dispatch) => {
    try {
      await api.documents.createDocument(data);
      dispatch(getDashboardData());
    } catch (error) {
      //console.log("Create document", error);
    }
  };
}

export function updateDocument(data, id) {
  return async (dispatch) => {
    try {
      await api.documents.updateDocument(data, id);
      dispatch(getDashboardData());
    } catch (error) {
      console.log("Delete document", error);
    }
  };
}
