import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

import Divider from "@mui/material/Divider";
import { getUsers, deleteUser } from "../../store/actions/user";
import { usersSelector } from "../../store/selectors/user";
import { FilterButton } from "../../components/PageFilters";
import { UserTable } from "../../components/Table/UserTable";
import { PageSearch } from "../../components/PageSearch";
import { useDebounce } from "../../hooks/useDebounce";

import "./style.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const buttons = [
  {
    id: 1,
    value: "All users",
  },
  {
    id: 2,
    value: "Active Users",
  },
  {
    id: 3,
    value: "Inactive Users",
  },
  {
    id: 4,
    value: "Administrators",
  },
  {
    id: 5,
    value: "Regular Users",
  },
];

export const UserManagementPage = () => {
  const dispatch = useDispatch();
  const [filteredValue, setFilteredValue] = useState("All users");
  const [searchValue, setSearchValue] = useState("");
  const [tableData, setData] = useState([]);
  const usersData = useSelector(usersSelector);
  const debouncedValue = useDebounce(searchValue);

  useEffect(() => {
    fetchUsers();
  }, [filteredValue]);

  useEffect(() => {
    const filteredData = filterBySearchQuery(usersData);
    setData(filteredData);
  }, [debouncedValue, usersData]);

  const fetchUsers = async () => {
    const queryParams = getQueryParam(filteredValue);
    dispatch(getUsers(queryParams));
  };
  const handleDeleteUser = async (id) => {
    await dispatch(deleteUser(id));
    await dispatch(getUsers());
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  const filterBySearchQuery = (data = []) => {
    return data?.filter((item) => {
      const FILTERED_FIELDS = [
        item.data.name,
        item.data.email,
        item.data.status,
        item.data.company.data.name,
      ];

      if (!debouncedValue) {
        return true;
      }
      return FILTERED_FIELDS.some((field) =>
        field.toLowerCase().includes(debouncedValue.toLowerCase())
      );
    });
  };

  const getQueryParam = (value) => {
    switch (value) {
      case "All users":
        return {};
      case "Active Users":
        return { status: "active" };
      case "Inactive Users":
        return { status: "inactive" };
      case "Administrators":
        return { companyAdmin: true };
      case "Regular Users":
        return { regularUser: true };
      default:
        return {};
    }
  };

  return (
    <div>
      <Grid
        container
        justifyContent="space-between"
        style={{
          alignItems: "center",
          paddingTop: "16px",
          paddingLeft: "24px",
          paddingRight: "24px",
        }}
      >
        <Grid item xs={9}>
          <Grid container flexDirection="column">
            <Grid item>
              <Breadcrumbs>
                <Typography style={{ fontSize: "12px" }}>Dashboard</Typography>
                <Typography style={{ fontSize: "12px", color: "black" }}>
                  User Management
                </Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item style={{ marginBottom: "16px" }}>
              <Typography style={{ fontSize: "24px", fontWeight: "600" }}>
                User Management
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Grid container flexDirection="column">
        <Grid item></Grid>
      </Grid>
      <Grid
        container
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0px 24px",
        }}
      >
        <Grid item>
          <PageSearch value={searchValue} handleSearch={handleSearch} />
        </Grid>
        <Grid item>
          <FilterButton
            buttons={buttons}
            filter={filteredValue}
            changeFilter={setFilteredValue}
          />
        </Grid>
        <Grid
          item
          style={{
            marginTop: "-31px",
          }}
        >
          <UserTable
            pageTitle={"Users"}
            addButtonTitle={"Add a User"}
            tableData={tableData}
            fetchData={fetchUsers}
          />
        </Grid>
      </Grid>
    </div>
  );
};
