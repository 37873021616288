import {
  FETCH_EXPENSES_START,
  FETCH_EXPENSES_END,
  SET_EXPENSES,
  CLEAR_EXPENSES,
} from "../../actionTypes";

const initialState = {
  expenses: [],
  expensesLoad: false,
};

export const expensesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_EXPENSES_START:
      return {
        ...state,
        expensesLoad: true,
      };
    case FETCH_EXPENSES_END:
      return {
        ...state,
        expensesLoad: false,
      };
    case SET_EXPENSES: {
      return {
        ...state,
        expenses: payload,
      };
    }
    case CLEAR_EXPENSES: {
      return {
        ...state,
        expenses: [],
      };
    }
    default:
      return state;
  }
};
