import moment from "moment";

export function formatDate(date, format = "MMMM D, YYYY") {
  if (!date) {
    return;
  }
  return moment(date).format(format);
}

export function formatDateToISOString(date) {
  if (!date) {
    return;
  }
  return moment(date).toISOString();
}

export function getMonthRange(startDate) {
  const dateNow = new Date();
  const monthNow = moment(dateNow).format("MMM YY");
  const monthStartDate = moment(startDate).format("MMM YY");
  let startOfMonth;

  if (monthNow === monthStartDate) {
    startOfMonth = 1;
  } else {
    startOfMonth = +moment(startDate).startOf("month").fromNow()[0];
  }

  let arr = [];
  for (let i = 0; i < startOfMonth; i++) {
    const date = moment(startDate).add(i, "month");
    arr.push(moment(date).format("MMM YY"));
  }
  return arr;
}
