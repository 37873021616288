import React from "react";
import Grid from "@mui/material/Grid";
import { BasicBreadcrumbs } from "./SearchBreadcrumbs";
import { CustomizedInputBase } from "./SearchField";
import Typography from "@mui/material/Typography";
import "./style.css";

export const PageSearch = ({ value, handleSearch }) => {
  return (
    <Grid
      container
      justifyContent="flex-start"
      style={{
        alignItems: "center",
        paddingTop: "24px",
        paddingBottom: "24px",
      }}
    >
      <Grid item>
        {/* <Grid container>
          <Grid item>
            <Typography>Showing</Typography>
          </Grid>
          <Grid
            item
            style={{
              margin: "0 7px",
            }}
          >
            <BasicBreadcrumbs />
          </Grid>
          <Grid
            item
            style={{
              marginRight: "7px",
            }}
          >
            <Typography
              style={{
                color: "grey",
              }}
            >
              Companies
            </Typography>
          </Grid>
        </Grid> */}
      </Grid>
      <Grid item>
        <CustomizedInputBase value={value} handleSearch={handleSearch} />
      </Grid>
    </Grid>
  );
};
