import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import {
  ModalContainer,
  ModalContent,
  ModalHeader,
} from "../../ui-components/ModalConstructor";
import { FormRenderer } from "../../Form/FormRender";
import { AppConfig } from "../../../config";
import { useState } from "react";
import {
  createDocument,
  updateDocument,
} from "../../../store/actions/document";
import queryString from "query-string";
import { getDashboardData } from "../../../store/actions/dashboard";

export const DocumentModal = ({
  onClose,
  title,
  description,
  topic,
  width = "500px",
  mode,
  documentId,
  companyId,
  projectId,
  ref,
}) => {
  const dispatch = useDispatch();
  const [documentUrl, setDocumentUrl] = useState("");

  useEffect(() => {
    if (mode === "EDIT") {
      setDocumentUrl(`${AppConfig.apiUrl}/document/submission/${documentId}`);
    } else {
      const params = {
        companyId,
        projectId,
      };
      const query = `?${queryString.stringify(params)}`;
      setDocumentUrl(`${AppConfig.apiUrl}/document${query}`);
    }
  }, [mode, documentId, companyId, projectId]);

  const handleCreateDocument = async (data) => {
    try {
      await dispatch(createDocument(data));
      // await dispatch(getDashboardData());
      onClose();
    } catch (error) {
      //console.log(error);
    }
  };

  const hundleUpdateDocument = async (data) => {
    try {
      await dispatch(updateDocument(data, documentId));
      // await dispatch(getDashboardData());
      onClose();
    } catch (error) {
      //console.log(error);
    }
  };

  const onSubmit = (submission) => {
    if (mode === "CREATE") {
      handleCreateDocument(submission);
    }

    if (mode === "EDIT") {
      hundleUpdateDocument(submission);
    }
  };

  return (
    <ModalContainer width={width} onClose={onClose} ref={ref}>
      <ModalHeader
        onClose={onClose}
        title={title}
        description={description}
        topic={topic}
        type="DOCUMENT"
      />
      <ModalContent>
        <FormRenderer url={documentUrl} onSubmit={onSubmit} />
      </ModalContent>
    </ModalContainer>
  );
};
