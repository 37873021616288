export const columns = [
  {
    // Necessary for edit/delete
    field: "_id",
    headerName: "Id",
    hide: true,
    valueGetter: (params) => {
      return params.row._id;
    },
  },
  {
    field: "data.name",
    headerName: "Name",
    sortable: true,
    flex: 1,
    valueGetter: (params) => {
      return params.row.data.name;
    },
  },
  // {
  //   field: "users",
  //   headerName: "Users",
  //   sortable: true,
  //   flex: 1,
  //   valueGetter: (params) => {
  //     return params.row.user;
  //   },
  // },
  {
    field: "data.status",
    headerName: "Status",
    sortable: true,
    flex: 1,
    valueGetter: (params) => {
      return params.row.data.status;
    },
  },
  {
    field: "created",
    headerName: "Created on",
    sortable: true,
    flex: 1,
  },
  {
    field: "modified",
    headerName: "Modified on",
    sortable: true,
    flex: 1,
  },
];
