import Base from "./Base";

export default class CompanyApi extends Base {
  getCompanies(params) {
    return this.apiClient.get("company/available", params);
  }
  getCompany(id) {
    return this.apiClient.get(`company/submission/${id}`);
  }
}
