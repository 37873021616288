import * as React from "react";

const Reminder = (props) => (
  <svg
    width={45}
    height={45}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width={45} height={45} rx={12} fill="#BB6BD9" fillOpacity={0.1} />
    <path
      d="M29.744 13c-.97 0-1.756.768-1.756 1.715 0 .031-.003.06 0 .092-1.925-.86-5.277-.5-8.401 2.55-1.49 1.453-4.705 1.12-5.625 2.015-.916.895-.753 3.852 3.133 7.646s6.888 3.936 7.808 3.036c.922-.901.65-3.809 2.254-5.376 3.056-2.983 3.331-6.323 2.445-8.249.047.003.094 0 .142 0 .97 0 1.756-.767 1.756-1.714S30.714 13 29.744 13Zm0 .857a.87.87 0 0 1 .878.858.87.87 0 0 1-.878.857.87.87 0 0 1-.878-.857c0-.23.095-.44.26-.603a.877.877 0 0 1 .618-.255Zm-3.584 2.086c.356.014.745.092 1.163.208-2.364-.113-5.378 3.569-6.502 4.912-.801.956-2.142 2.002-3.82 2.132-1.377-.779-1.282-1.83-1.282-1.83s1.886.269 4.414-1.6c2.346-1.734 3.53-3.924 6.027-3.822Zm-8.068 12.952a1.705 1.705 0 0 0-.736 1.39c0 .948.786 1.715 1.756 1.715.858 0 1.575-.6 1.733-1.39-.795-.354-1.724-.898-2.753-1.715Z"
      fill="#BB6BD9"
    />
  </svg>
);

export default Reminder;
