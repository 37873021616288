import Base from "./Base";

export default class ExpensesApi extends Base {
  updateExpenses(body) {
    return this.apiClient.put("cost_expenses/submission/update", body);
  }
  createExpenses(body) {
    return this.apiClient.post("cost_expenses/submission", body);
  }
  getExpenses(id) {
    return this.apiClient.get(`dashboard/projectExpenses/${id}`);
  }
}
