import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "../Icons/Search";

export function CustomizedInputBase({ value, handleSearch }) {
  return (
    <Paper
      component="form"
      sx={{
        display: "flex",
        alignItems: "center",
        width: "350px",
        height: "32px",
        border: "1px solid #BDBDBD",
        borderRadius: "4px",
        boxShadow: "none",
      }}
    >
      <IconButton
        type="submit"
        sx={{ p: "10px" }}
        aria-label="search"
        backgroundColor="#BDBDBD"
      >
        <SearchIcon />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="Search"
        inputProps={{ "aria-label": "search" }}
        value={value}
        onChange={handleSearch}
        style={{
          fontFamily: "Inter",
          fontSize: "14px",
          color: "#9C9C9C",
          margin: "10px 0px",
        }}
      />
    </Paper>
  );
}
