import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { CircularProgress, Modal } from "@mui/material";
import Box from "@mui/material/Box";
import "./style.css";

export const ModalContainer = forwardRef(
  ({ children, onClose, loading, width = "540px" }, ref) => {
    if (loading) {
      return (
        <div className="spinnerWrapper">
          <CircularProgress color={"primary"} size={50} />
        </div>
      );
    }

    return (
      <Modal
        open
        onClose={onClose}
        className="modal_container"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          data-testid={"modal-content"}
          className="modal_content"
          style={{ width }}
        >
          {children}
        </div>
      </Modal>
    );
  }
);

ModalContainer.propTypes = {
  children: PropTypes.node,
  width: PropTypes.string,
  onClose: PropTypes.func,
  maxHeight: PropTypes.string,
  loading: PropTypes.bool,
};

ModalContainer.defaultProps = {
  children: null,
  width: "530px",
  onClose: () => {},
  maxHeight: "80vh",
  loading: false,
};
