import React from "react";
import { Outlet } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import { MainMenu } from "../../components/Menu";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./style.css";

import defaultLogo from "../../assets/icons/Logo.png";
import { sessionSelector } from "../../store/selectors/session";
import { Auth } from "../../modules/auth";

export const MainLayout = () => {
  const navigate = useNavigate();
  const auth = Auth();
  const userData = useSelector(sessionSelector);
  const companyLogoUrl = userData?.company?.data?.logo;

  const handleLogout = async () => {
    try {
      auth.logOut();
      if (auth.isAuthenticated() === "false") {
        // localStorage.clear(); // intentionally commented out
        navigate("/");
      }
    } catch (error) {
      //console.log("Logout error", error);
    }
  };

  return (
    <Grid container columns={16} className="main_layout">
      <Grid item className="sider">
        <Grid
          container
          direction={"column"}
          style={{
            justifyContent: "space-between",
            paddingTop: "8px",
            height: "100vh",
            position: "fixed",
            width: "64px",
            overflow: "auto",
          }}
        >
          <Grid
            container
            style={{
              height: "100%",
            }}
          >
            <div className="logo">
              <Avatar
                alt="Logo"
                src={companyLogoUrl ?? defaultLogo}
                sx={{ width: 48, height: 48 }}
              />
            </div>
            <div className="menu">
              <MainMenu />
            </div>
            <div className="avatar">
              <Avatar
                sx={{ width: 40, height: 40 }}
                alt="user"
                onClick={handleLogout}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className="main_wrapper">
        <Outlet />
      </Grid>
    </Grid>
  );
};
