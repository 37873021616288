import * as React from "react";

const LinkIcon = (props) => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.853 6.147a3.015 3.015 0 0 1 0 4.265l-1.705 1.705a3.015 3.015 0 1 1-4.265-4.264l1.499-1.455"
      stroke="#4F4F4F"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.147 7.853a3.015 3.015 0 0 1 0-4.264l1.706-1.706a3.015 3.015 0 1 1 4.264 4.264L10.62 7.603"
      stroke="#4F4F4F"
      strokeWidth={1.5}
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default LinkIcon;
